// @flow

import { put, takeLatest,all,call, takeEvery,takeLeading } from 'redux-saga/effects';
import { fetchJSON ,insertJSON,SERVERURI,TOKEN} from '../../../helpers/api';
import {
    SET_PROSPECT,
    PROSPECT_ADDED,
    PROSPECT_REQUEST_FAILED,
    PROSPECT_PATCHED,
    PATCH_PROSPECT,

    PROSPECTS_RECEIVED,
    GET_PROSPECTS,
    DELETE_PROSPECT,
    PROSPECT_DELETED,
    AFFECT_PROSPECT,
    PROSPECT_AFFECTED,
    GET_PROSPECT,
    VERIFY_PROSPECT,
    VALID_PROSPECT_RECEIVED,
    NOTIFY_PROSPECT_USER,
    PROSPECT_RECEIVED,
    SET_OPPORTUNITE_FROM_PROSPECT,
    OPPORTUNITE_ADDED_FROM_PROSPECT
} from './constants';

import {SET_OPPORTUNITE,OPPORTUNITE_ADDED} from '../opportunite/constants';

import moment from 'moment';
import 'moment-duration-format';

import 'moment/min/moment-with-locales'
import {setOpportunite} from "../opportunite/actions";
//import {AGENCE_REQUEST_FAILED} from "./agence/constants";
function renderRole(role,idCommercial,idAgence){


    switch (role) {

        case 'mesProspects':
            return  "?createdBy.id="+idCommercial;
        case 'prospectsAgence':
            return  "?agenceCreatrice.id="+idAgence;
        case 'allProspects':
            return  "";
        default:

            return "";
    }
}
function* getProspects(action, baseUrl)
{
    const options = {
        method: 'GET',
        credentials: 'same-origin',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN()}` ,
            },
    };
    try {
        const { role,idCommercial,idAgence} = action.payload;

        const response = yield call(fetchJSON, SERVERURI+'/prospects'+renderRole(role,idCommercial,idAgence), options);
        yield put({ type: PROSPECTS_RECEIVED, json: response["hydra:member"],role,status:"info",notifyMessage:response["hydra:totalItems"]+" prospects trouvés"});
    }
    catch (error) {
        let message;
        switch (error) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            case 404:
                message = 'Invalid credentials';
                break;
            default:
                //message = error;

        }
        yield put({ type: PROSPECTS_RECEIVED, json: error,status:"error", notifyMessage:"Une erreur est survenue veuillez contacter l'administrateur"});

    }
}



function* verifyProspect(action)
{
    const { tel1,tel2,email} = action.payload;

    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };


    try {

        let response=[]
        let isValid=true

        if(tel1!==null&&tel1.tel1?.trim().length > 0)
        {
            const verifTel10 = yield call(fetchJSON,SERVERURI+'/prospects?tel1='+tel1.tel1, options);
            if(verifTel10["hydra:member"].length>0){
                isValid=false
                response.push(verifTel10["hydra:member"][0])
            }
            const verifTel11 = yield call(fetchJSON,SERVERURI+'/prospects?tel2='+tel1.tel1, options);
            if(verifTel11["hydra:member"].length>0){
                isValid=false
                response.push(verifTel11["hydra:member"][0])
            }
        }
        if(tel2!==null&&tel2.tel2?.trim().length > 0)
        {
            const verifTel20 = yield call(fetchJSON,SERVERURI+'/prospects?tel1='+tel2.tel2, options);
            if(verifTel20["hydra:member"].length>0){
                isValid=false
                response.push(verifTel20["hydra:member"][0])
            }
            const verifTel21 = yield call(fetchJSON,SERVERURI+'/prospects?tel2='+tel2.tel2, options);
            if(verifTel21["hydra:member"].length>0){
                isValid=false
                response.push(verifTel21["hydra:member"][0])
            }
        }
        if(email!==null&&email?.trim().length > 0){
            const verifEMAIL = yield call(fetchJSON,SERVERURI+'/prospects?email='+email, options);
            if(verifEMAIL["hydra:member"].length>0){
                response.push(verifEMAIL["hydra:member"][0])
                isValid=false
            }
        }




        yield put({
            type: VALID_PROSPECT_RECEIVED,
            isValid,
            response,
        });


    }
    catch (error) {

        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: PROSPECT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Prospect existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}


function* setProspect(action)
{
    const { data,history} = action.payload;



   // const commercial= yield call(fetchJSON,SERVERURI+'/users/'+data.owner.id,optionss);
    const options = {
        method: 'POST',
        Accept: 'application/json',
        headers:{
           'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            //  Nom:data.Nom,
            prenom: data.prenom,
            nom: data.nom,
            ste: data.ste,
            sm: data.sm,
            email: data.email===''?null:data.email,
            tel1: data.tel1.tel1,
            tel2: data.tel2===null?null:data.tel2.tel2,
            adresse:data.adresse,
            longitude:parseFloat(data.lng),
            latitude:parseFloat(data.lat),
            isSociete:data.isSociete,
           // refSteg: data.refSteg.toString(),
            remarque: data.remarque,
         //   fichier:data.fichier,
            villeProspect:data.ville,
            canalProspect:data.canaux,
            segments:data.segments,
            createdBy:data.createdBy,
           // chargeCommercial:null,
            agenceCreatrice:data.agence,
        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/prospects', options);

        yield put({
            type: PROSPECT_ADDED,
            data,
            status:"success",notifyMessage:"Félicitations le prospect a été ajouté avec succés",
              addedProspect:response
        });

        yield call(() => {

            history.push('/prospects/prospects');
        });

     //   yield* getProspects()
       // yield* createOpportuniteFromProspect(action)



    }
    catch (error) {

        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: PROSPECT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Prospect existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put({
            type: PROSPECT_ADDED,

            status:"error",notifyMessage:"Une erreur est survenue veuillez réessayer"
        });
    }
}

function* createOpportuniteFromProspect(action)
{
    const { data,history} = action.payload;

    const options = {
        method: 'POST',
        Accept: 'application/json',
        headers:{
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            prenom: data.prenom,
            nom: data.nom,
            ste: data.ste,
            sm: data.sm,
            adresse:data.adresse,
            email: data.email,
            tel1: data.tel1,
            tel2: data.tel2,
            refSteg: data.refSteg,
            remarque: data.remarque,
            villeOpportunite:data.ville[0],
            canalOpportunite:data.canaux[0],
            segments:data.segments,
            createdBy:data.owner,
            chargeCommercial:data.owner
        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/opportunites', options);
        yield put({
            type: OPPORTUNITE_ADDED_FROM_PROSPECT,
            data,
            statusOppProspectMessage:"success",notifyOppProspectMessage:"Félicitations l'opportunité a été créee"

        });
        //     yield put({ type: PROSPECTS_RECEIVED, json: response["hydra:member"],status:"info",notifyMessage:response["hydra:totalItems"]+" prospects trouvés"});
    }
    catch (error) {

        let message;
        switch (error.status) {
            case 500:

                 message="le Prospect existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put({
            type: OPPORTUNITE_ADDED_FROM_PROSPECT,

            statusOppProspectMessage:"error",notifyOppProspectMessage:"Une erreur est survenue lors de la création de l'opportunité"
        });
    }
}


function* updateProspect(action)
{
    const { data,history} = action.payload;
    const options = {
        method: 'PUT',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            nom:data.nom,
            prenom:data.prenom,
            ste:data.ste,
            isSociete:data.isSociete,
            tel1:data.tel1,
            tel2:data.tel2,
            email:data.email,
            adresse:data.adresse,
            longitude:parseFloat(data.longitude),
            latitude:parseFloat(data.latitude),
            villeProspect:data.villeProspect['@id'],
            agenceCreatrice:data.agenceCreatrice['@id'],
            createdBy:data.createdBy['@id']
        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/prospects/'+data.id, options);
        yield put({
            type: PROSPECT_PATCHED,
            updatedProspect:response.json,
        });
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: PROSPECT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Prospect existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* affectProspectToCommercial(action)
{

    const{data,history}=action.payload


    const getOptions = {
        method: 'GET',
        // Accept: 'application/json',
        headers:{
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        }
    }

    let dateDebut=data.date


       if(dateDebut!==null){dateDebut=data.date}else{dateDebut=moment(moment().toDate()).format('YYYY-MM-DD HH:mm:ss')}
    for (var prospect in data.prospects) {

        const duration=data.selectedPriorite


        const prospectAffected=  yield call(fetchJSON,SERVERURI+'/prospects/'+data.prospects[prospect],getOptions);
        const deadline= moment(dateDebut). add(duration['delais']+1, 'hours',);

        if(
           data.remarque.trim() !=""){
           let OpporutniteOptions = {
            method: 'POST', Accept: 'application/json',
            headers:{
                'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
            },
            body:JSON.stringify({
                // prospect:prospects[0],
                segment:data.segment,
                avancement:data.selectedAction['@id'],
                assignedCommercial:data.executeur,
                agence:data.agence,
                startDate:dateDebut,
                deadline:deadline,
                prospect:[prospectAffected['@id']],
                owner:data.demandeur['@id'],
                nom:prospectAffected['fullName'],
                etat:data.etat['@id'],
                nbInstallations:0,
                remarqueOpportunite:[{
                    note:data.remarque,
                    editeur:data.demandeur.commercial['@id'],
                  //  dateRedaction:data.date[0],
                }],
                tachesAssociees:[{
                    demandeur:data.demandeur['@id'],
                    executeur:data.executeur,
                   // categorie:categorie['@id'],
                    action:data.selectedAction['@id'],
                    demande:data.remarque,
                    priorite:duration['@id'],
                    deadline:deadline,
                    prospect:prospectAffected['@id'],
                   startDate:dateDebut,
                   agenceTache: data.agenceTache
                }

                ]


            })
        }
            try {

                const response = yield call(fetchJSON,SERVERURI+'/prospects/'+data.prospects[prospect], getOptions);

                const response2 = yield call(insertJSON,SERVERURI+'/opportunites', OpporutniteOptions);
                yield put({
                    type: PROSPECT_AFFECTED,status:"info", notifyMessage:"Affectation de: "+ prospectAffected['fullName']+ " effectuée"
                });
                yield call(() => {
                    history.push('/travaux/taches-en-cours');
                });
            }
            catch (error) {
                let message;
                switch (error.status) {
                    case 500:

                        yield put({
                            type: PROSPECT_REQUEST_FAILED,
                            error:error.status,
                            response:"Le Champ doit etre unqiue",
                            //json: response["hydra:member"],
                        });
                        message="le Prospect existe veuillez choisir un autre nom"
                    case 400:
                        message = 'Echec connexion';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }
        }else{
            let OpporutniteOptions = {
                method: 'POST',
                headers:{
                    Accept: 'application/json',
                    'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
                },
                body:JSON.stringify({
                    // prospect:prospects[0],
                    segment:data.segment,
                    avancement:data.selectedAction['@id'],
                    assignedCommercial:data.executeur,
                    agence:data.agence,
                    startDate:dateDebut,
                    deadline:deadline,
                    prospect:[prospectAffected['@id']],
                    owner:data.demandeur['@id'],
                    nom:prospectAffected['fullName'],
                    etat:data.etat['@id'],
                    nbInstallations:0,


                    tachesAssociees:[{
                        demandeur:data.demandeur['@id'],
                        executeur:data.executeur,
                        // categorie:categorie['@id'],
                        action:data.selectedAction['@id'],
                        demande:data.remarque,
                        priorite:duration['@id'],
                        deadline:deadline,
                        prospect:prospectAffected['@id'],
                        startDate:dateDebut,
                        agenceTache: data.agenceTache
                    }

                    ]
                })
            }

            try {

               const response = yield call(fetchJSON,SERVERURI+'/prospects/'+data.prospects[prospect], getOptions);
                const response2 = yield call(insertJSON,SERVERURI+'/opportunites', OpporutniteOptions);

                yield put({
                    type: PROSPECT_AFFECTED,status:"info", notifyMessage:"Affectation de:"+ prospectAffected['fullName']+ " effectuée"     // propsects,
                });
                yield call(() => {
                    history.push('/travaux/taches-en-cours');
                });
            }
            catch (error) {
                let message;
                switch (error.status) {
                    case 500:
                        yield put({
                            type: PROSPECT_REQUEST_FAILED,
                            error:error.status,
                            response:"Le Champ doit etre unqiue",
                            //json: response["hydra:member"],
                        });
                        message="le Prospect existe veuillez choisir un autre nom"
                    case 400:
                        message = 'Echec connexion';
                        break;
                    case 401:
                        message = 'Invalid credentials';
                        break;
                    default:
                        message = error;
                }
            }
        }
    }
  //  yield* getProspects(action)
}

function* getProspect(action)
{
    const{idProspect}=action.payload

    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/prospects/'+idProspect, options);


        yield put({ type: PROSPECT_RECEIVED, json: response});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}






function* deleteProspect(action)
{
    const { history,id} = action.payload;
    const options = {
        method: 'DELETE',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            id
        })
    }
    try {

        const response = yield call(fetchJSON,SERVERURI+'/prospects/'+id, options);
        yield put({
            type: PROSPECT_DELETED,
            //data,

        });
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: PROSPECT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Prospect existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* prospectWatcher() {
    yield takeEvery(GET_PROSPECTS, getProspects)
    yield takeEvery(SET_PROSPECT, setProspect)
    yield takeEvery(PATCH_PROSPECT, updateProspect)
    yield takeEvery(AFFECT_PROSPECT, affectProspectToCommercial)
    yield takeEvery(DELETE_PROSPECT, deleteProspect)
    yield takeEvery(GET_PROSPECT, getProspect)
    yield takeEvery(VERIFY_PROSPECT, verifyProspect)
    yield takeEvery(SET_OPPORTUNITE_FROM_PROSPECT,createOpportuniteFromProspect)

}
////////////////////////////////////////
//////USERS SAGA////////////////////////
////////////////////////////////////////
export default function* prospectSaga() {
    yield all([
        prospectWatcher()
    ]);
}
