import React from 'react';
import { connect } from 'react-redux';
import { getUsers,usersReceived,getSelectedVille,getSelectedUser } from '../redux/crm/actions';
import {AvField} from "availity-reactstrap-validation";
import {getLoggedInUser} from "../helpers/authUtils";

class UsersSelectSingle extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            selectedUser:null
        }
        this.handleChange = this.handleChange.bind(this);


    }


    handleChange=(e)=>{

        let id=e.target.value
        let cible = this.props.users.filter(us =>
            (us.id.toString() === id.toString()))
        this.setState({
            selectedUser:cible[0]
        })

        this.props.getSelectedUser(cible[0])
    }
    componentDidMount() {
        let role = this.props.role
        let selectName=this.props.name
        let userId=this.props.userId
        let agenceId=this.props.agenceId
        //console.log("hello saga", this.props.role)
        //const  testFilter ={"filter":"?roles=ROLE_COORDINATEUR"}

        console.log("Role",role)
        console.log("UserId",userId)
        console.log("agenceId",agenceId)
        this.props.getUsers( role,"",userId,agenceId)

     //   alert(JSON.stringify(this.props.users))
// this.props.getSelectedVille(id, ville)
     //   this.setState({selectedUser: this.props.users[0]})


    // this.setState({selectedVille:52})
    }
    getAsyncData = async (actions) => {
        return new Promise((resolve) => {
            resolve(actions);
        });
    };
componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedUser !== this.props.selectedUser) {

        if(this.props.selectedUser===undefined){

            this.getAsyncData(this.props.users).then((users) => {

                this.setState({
                    selectedUser: users[0].id
                }, function () {
                })



            })
        }
        else{
            this.setState({
                selectedUser: this.props.selectedUser.id
            }, function () {
            })
        }


    }
    if(prevProps.agenceId!==this.props.agenceId){

        let role = this.props.role
        let selectName=this.props.name
        let userId=this.props.userId
        let agenceId=this.props.agenceId
        //console.log("hello saga", this.props.role)
        //const  testFilter ={"filter":"?roles=ROLE_COORDINATEUR"}
        console.log("Role",role)
        console.log("UserId",userId)
        console.log("agenceId",agenceId)
        this.props.getUsers( role,"",userId,agenceId)

    }

    if(prevProps.users!==this.props.users){
        this.props.getSelectedUser(this.props.users[0]);
    }
    console.log("uuuuuuuuuuuuuuuuuuuuser",this.props.selectedUser)
}

    expose_users =({users})=> (
        users ?
                <AvField type="select" name={this.props.name}  label={this.props.label}  helpMessage={this.props.helpMessage}
                onChange={this.handleChange}
                         /*value={this.state.selectedUser===null?this.props.users[0].id:this.state.selectedUser}*/
                         value={ this.state.selectedUser===null||this.state.selectedUser===undefined?this.props.default.id:this.state.selectedUser.id}
                >

                {users.map((user,index)=><option key={index} value={user.id}>{user.prenom} {user.Nom} </option>)}
            </AvField>


        :null
);

render(){
return(

                   <div>
                       {console.log("users received", this.props.users)}
                       {this.expose_users(this.props)}
                  </div>

)
}


}

const mapDispatchToProps = {
getUsers,getSelectedUser
};
const mapStateToProps = (state,action) => ({
users: state.Crm.users, selectedUser: state.Crm.selectedUser,
});
UsersSelectSingle = connect(mapStateToProps,mapDispatchToProps ,null)(UsersSelectSingle)

export default UsersSelectSingle;