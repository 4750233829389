import React, { Component, Suspense } from 'react';
import {
    Row,
    Col,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Button,
    Collapse,
    CardBody,
    FormGroup,
    Label,
    CustomInput,
    Card,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import {
    ChevronDown,
    Mail,
    Printer,
    File,
    Users,
    Image,
    ShoppingBag,
    Briefcase,
    CheckCircle,
    XCircle,
    default as FeatherIcon,

} from 'react-feather';

import Loader from '../../components/Loader';
import OverviewWidget from '../../components/OverviewWidget';

import Statistics from './Statistics';
import RevenueChart from './RevenueChart';
import TargetChart from './TargetChart';
import SalesChart from './SalesChart';
import Orders from './Orders';
import Performers from './Performers';
import Tasks from './Tasks';
import Chat from './Chat';
import StatisticsChartWidget from '../../components/StatisticsChartWidget';
import moment from 'moment';
import 'moment-duration-format';

import 'moment/min/moment-with-locales';

import 'moment/locale/fr';
import { getLoggedInUser } from '../../helpers/authUtils';
import { getTaches, updateTache } from '../../redux/crm/tache/actions';
import { getCanaux, getSegments, getSelectedCanal, getSelectedVille, getVilles,getUsers } from '../../redux/crm/actions';
import { affectProspect, getProspects, setProspect } from '../../redux/crm/prospect/actions';
import {
    getIncomingOpportunites,
    getOpportunites,
    getOthersOpportunites,
    getTodayOpportunites,
} from '../../redux/crm/opportunite/actions';
import { connect } from 'react-redux';
import TachesEnCours from '../opportunites/TachesEnCours';
import * as _ from 'lodash';
import avatar from '../../assets/images/users/user.png';
import { SERVERUIMAGESUSERS } from '../../helpers/api';
import StatisticsProgressWidget from "../../components/StatisticsProgressWidget";
import StatisticsChartWidget2 from "../../components/StatisticsChartWidget2";
import StatisticsWidget from "../../components/StatisticsWidget";
import Chart from "react-apexcharts";
const French = require('flatpickr/dist/l10n/fr.js').default.fr;
function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function dateJour(date) {
    return moment(date).format('YYYY-MM-DD');
}

function getAverage(current, last) {
    let average=0
    if (_.sum(last) <= _.sum(current)) {
        if (
            _.sum(last) !== null &&
            _.sum(last) != undefined &&
            _.sum(last) !== 0 &&
            _.sum(current) !== null
        ) {
            average = ((_.sum(current) / _.sum(last)) * 100).toFixed(2);
        } else {
            average= null;
        }
    } else {
        if (
            _.sum(current) !== null &&
            _.sum(current) != undefined &&
            _.sum(last) !== 0 &&
            _.sum(current) !== null
        ) {
            average = (-(_.sum(last) / _.sum(current)) * 100).toFixed(2);
        } else {
            average = null;
        }
    }
    return average
}

class Dashboard extends Component {
    constructor(props) {
        super(props);

        var oneMonthAgo = new Date();
        oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);

        this.state = {
            //user: this.getLoggedInUser(),
            filterDate: [oneMonthAgo, new Date()],
            panel1: false,
            panel2: false,
            panel3: false,
        };


        this.handleTacheRetard = this.handleTacheRetard.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
        this.customInputSwitched = this.customInputSwitched.bind(this);

        this.searchByFilter = this.searchByFilter.bind(this);
        this.customInputSwitchedEtat = this.customInputSwitchedEtat.bind(this);
    }

    searchByFilter(e) {
        if (this.state.taches !== undefined && this.state.filterDate !== undefined) {
            let result = this.state.taches === undefined ? [] : this.state.taches;
            /* let searchByKey*/
            let startDate = Date.parse(this.state.filterDate[0]);
            let endDate = Date.parse(this.state.filterDate[1]);
            let peridodDay = moment.duration(moment(endDate).diff(moment(startDate))).asDays()+1
            let lastPeriodEndDate = moment(startDate).subtract(1,'days')
            let lastPeriodStartDate  = moment(lastPeriodEndDate).subtract(peridodDay-1,'days')


            var admission = moment(startDate).format('DD-MM-YYYY');
            var discharge = moment(endDate).format('DD-MM-YYYY');

            /*var oneMonthAgo = new Date();
            oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
            */


            ///////// stat Chiffre d'affaires////////////////////////////////////
            let searchByPeriod = [];
            let searchAllTasksByPeriod=[];
            let searchPerformedTasksByPeriod=[];
            let searchDelayedTasksByPeriod=[];

            searchAllTasksByPeriod= this.state.taches.filter(
                (tache) =>

                   moment(tache.startDate).isSameOrAfter(moment(startDate).format('YYYY-MM-DD')) &&
                   moment(tache.startDate).isSameOrBefore(moment(endDate).add(1,'days').format('YYYY-MM-DD'))



            );
            let allTasks=[]
            let allTasksData=_.groupBy(searchAllTasksByPeriod, 'startDate');
            Object.keys(allTasksData).map((period, i) => {
                allTasks.push(allTasksData[`${period}`].length);
            });


            searchPerformedTasksByPeriod = searchAllTasksByPeriod.filter(
                (tache) =>
                    tache.accompli===true
            );

            let performedTasks=[]
            let performedTasksData=_.groupBy(searchPerformedTasksByPeriod, 'startDate');
            Object.keys(performedTasksData).map((period, i) => {
                performedTasks.push(performedTasksData[`${period}`].length);
            });

            searchDelayedTasksByPeriod = searchAllTasksByPeriod.filter(
                (tache) =>
                    tache.retard===true && (tache.accompli===false||tache.accompli===undefined)
            );
            let delayedTasks=[]
            let delayedTasksData=_.groupBy(searchDelayedTasksByPeriod, 'startDate');
            Object.keys(delayedTasksData).map((period, i) => {
                delayedTasks.push(delayedTasksData[`${period}`].length);
            });
            searchByPeriod = this.state.taches.filter(
                (tache) =>
                    tache.opportuniteParent.etat.id === 3&&
                    moment(tache.opportuniteParent.dateCloture).isSameOrAfter(moment(startDate).format('YYYY-MM-DD')) &&
                    moment(tache.opportuniteParent.dateCloture).isSameOrBefore(moment(endDate).add(1,'days').format('YYYY-MM-DD'))
                   /* Date.parse(tache.opportuniteParent.dateCloture) >= startDate &&
                    Date.parse(tache.opportuniteParent.dateCloture) <= endDate*/
            );
            searchByPeriod = _.uniqBy(searchByPeriod, 'opportuniteParent.id');








            ///////// stat opportunites////////////////////////////////////

            let searchOpportuniteByPeriod = [];
            let searchOpportuniteByLastPeriod = [];
            let searchPerformedOpportuniteByPeriod = [];
            let searchConvertedOpportuniteByPeriod=[]
            let amountPendingOpportuinites=0
            let searchPerformedOpportuniteByLastPeriod = [];
            let searchLostedOpportuniteByPeriod = [];
            let searchLostedOpportuniteByLastPeriod = [];
            let currentCA = [0];
            let salesSegment=[];
            let colorsSegment=[];
            let dataSegment=[];
            let opportunitewithOffer=0
            if (this.state.opportunites !== undefined) {
                searchOpportuniteByPeriod = this.state.opportunites.filter(
                    (opportunite) =>
                        moment(opportunite.startDate).isSameOrAfter(moment(startDate).format('YYYY-MM-DD')) &&
                        moment(opportunite.startDate).isSameOrBefore(moment(endDate).add(1,'days').format('YYYY-MM-DD'))
                );

                searchOpportuniteByPeriod.map((opportunite)=>{
                    if (opportunite.etat.id===1&&(opportunite.ca !== undefined && opportunite.ca > 0)) {
                        amountPendingOpportuinites=amountPendingOpportuinites+ opportunite.ca
                    }
                    if (opportunite.ca !== undefined && opportunite.ca >0) {
                        opportunitewithOffer++
                    }
                })
                searchPerformedOpportuniteByPeriod=this.state.opportunites.filter((opportunite)=>
                    opportunite.etat.id==3&&
                    moment(opportunite.dateCloture).isSameOrAfter(moment(startDate).format('YYYY-MM-DD')) &&
                   moment(opportunite.dateCloture).isSameOrBefore(moment(endDate).add(1,'days').format('YYYY-MM-DD'))

                )
                searchConvertedOpportuniteByPeriod=searchOpportuniteByPeriod.filter((opportunite)=>
                    opportunite.etat.id==3&&
                    moment(opportunite.dateCloture).isSameOrAfter(moment(startDate).format('YYYY-MM-DD')) &&
                    moment(opportunite.dateCloture).isSameOrBefore(moment(endDate).add(1,'days').format('YYYY-MM-DD'))

                )
                searchPerformedOpportuniteByPeriod.map((opportunite)=>{
                    if (opportunite.ca !== undefined && opportunite.ca > 0) {
                        currentCA.push(opportunite.ca);
                    }

                })
                let dataSegmentCA=[]
                dataSegment= _.groupBy(searchPerformedOpportuniteByPeriod, 'segment.id');

                Object.keys(dataSegment).map((segmentId, i) => {

                    salesSegment.push(dataSegment[`${segmentId}`][0].segment.nom)
                    colorsSegment.push(dataSegment[`${segmentId}`][0].segment.couleur)
                    let valeurCA=0;
                    dataSegment[`${segmentId}`].map((opportunite,index)=>{
                        if (opportunite.ca !== undefined && opportunite.ca > 0) {
                            valeurCA=valeurCA+opportunite.ca
                        }
                    })
                    dataSegmentCA.push(valeurCA)
                });
                //currentCA.push(0)
                let searchOpportuniteByLastPeriod = [];
                searchOpportuniteByLastPeriod = this.state.opportunites.filter(
                    (opportunite) =>
                        opportunite.etat.id === 3 &&
                        moment(opportunite.startDate).isSameOrAfter(moment(lastPeriodStartDate).format('YYYY-MM-DD')) &&
                        moment(opportunite.startDate).isSameOrBefore(moment(lastPeriodEndDate).add(1,'days').format('YYYY-MM-DD'))
                    //  &&tache.startDate<=this.state.filterDate[1]
                );
                searchOpportuniteByLastPeriod = _.uniqBy(searchOpportuniteByLastPeriod, 'id');
                let lastCA = [0];
                searchOpportuniteByLastPeriod.map((opportunite) => {
                    if (opportunite.ca !== undefined &&opportunite.ca > 0) {
                        lastCA.push(opportunite.ca);

                    }
                });

                searchLostedOpportuniteByPeriod=searchOpportuniteByPeriod.filter((opportunite)=>
                    opportunite.etat.id===2
                )
                searchOpportuniteByPeriod = _.groupBy(searchOpportuniteByPeriod, 'startDate');

                searchOpportuniteByLastPeriod = this.state.opportunites.filter(
                    (opportunite) =>
                        moment(opportunite.startDate).isSameOrAfter(moment(lastPeriodStartDate).format('YYYY-MM-DD')) &&
                        moment(opportunite.startDate).isSameOrBefore(moment(lastPeriodEndDate).add(1,'days').format('YYYY-MM-DD'))
                );
                searchOpportuniteByLastPeriod = _.groupBy(searchOpportuniteByLastPeriod, 'startDate');
                let oppKeys = Object.keys(searchOpportuniteByPeriod);
                let currentOpp = [0];


                Object.keys(searchOpportuniteByPeriod).map((period, i) => {
                    currentOpp.push(searchOpportuniteByPeriod[`${period}`].length);


                });
                let lastOpp = [0];
                Object.keys(searchOpportuniteByLastPeriod).map((period, i) => {
                    lastOpp.push(searchOpportuniteByLastPeriod[`${period}`].length);
                });

                const optionsSalesSegments = {
                    chart: {
                        height: 302,
                        type: 'donut',
                        toolbar: {
                            show: false,
                        },
                        parentHeightOffset: 0,
                    },
                    colors: ["#5369f8"],
                    grid: {
                        borderColor: '#f1f3fa',
                        padding: {
                            left: 0,
                            right: 0,
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '70%',
                            },
                            expandOnClick: false
                        }
                    },
                    legend: {
                        show: true,
                        position: 'right',
                        horizontalAlign: 'left',
                        itemMargin: {
                            horizontal: 6,
                            vertical: 3
                        }
                    },
                    labels: ['Clothes 44k'],
                    responsive: [{
                        breakpoint: 480,
                        options: {

                            legend: {
                                position: 'bottom'
                            }
                        }
                    }],
                    tooltip: {
                        y: {
                            formatter: function(value) { return value + " TND" }
                        },
                    }
                };
                let segmentSales=optionsSalesSegments
                segmentSales = {
                    chart: {
                        height: 302,
                        type: 'donut',
                        toolbar: {
                            show: false,
                        },
                        parentHeightOffset: 0,
                    },
                    colors: colorsSegment,
                    grid: {
                        borderColor: '#f1f3fa',
                        padding: {
                            left: 0,
                            right: 0,
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '70%',
                            },
                            expandOnClick: false
                        }
                    },
                    legend: {
                        show: true,
                        position: 'right',
                        horizontalAlign: 'left',
                        itemMargin: {
                            horizontal: 6,
                            vertical: 3
                        }
                    },
                    labels: salesSegment,
                    responsive: [{
                        breakpoint: 480,
                        options: {

                            legend: {
                                position: 'bottom'
                            }
                        }
                    }],
                    tooltip: {
                        y: {
                            formatter: function(value) { return value + " TND" }
                        },
                    }
                };
                let objectifs=[]
                let objectifSegmentNames=[]
                let nbProjetData=[]
                let puissanceData=[]
                let caData=[]
                let objectifData=[]
                let targetName=""
                let objectifCategories=[]
                let performedObjectif=[]

                if(this.state.users!==undefined)
                {   let objectifCommercial=this.state.users.ObjectifCommercialsUser
                    objectifCommercial.map((objectif,index)=>{
                        let objectifNbprojet=0
                        let objectifPuissance=0
                        let objectifCa=0

                        objectifs.push(
                            {
                                id:objectif.segment[0].id,
                                nom:objectif.segment[0].nom,
                                nbProjet:(objectif.nbProjet/31)*peridodDay,
                                puissance:(objectif.puissance/31)*peridodDay,
                                ca:(objectif.ca/31)*peridodDay,
                            }

                        )

                         objectifNbprojet=(objectif.nbProjet/31)*peridodDay
                        objectifPuissance=(objectif.puissance/31)*peridodDay
                         objectifCa=(objectif.ca/31)*peridodDay

                        let nbProjet=0
                        let ca=0
                        let puissance=0

                        searchPerformedOpportuniteByPeriod.map((opportunite,index)=>{

                            if(objectif.segment[0].id==opportunite.segment.id){
                                nbProjet=nbProjet+1
                                if (opportunite.ca !== undefined &&opportunite.ca > 0) {
                                    ca=ca+opportunite.ca
                                }

                                puissance=opportunite.puissance
                            }
                        })
                        nbProjetData.push(((nbProjet/objectifNbprojet)*100).toFixed(2))
                        caData.push(((ca/objectifCa)*100).toFixed(2))
                        puissanceData.push(((puissance/objectifPuissance)*100).toFixed(2))
                        objectifCategories.push(objectif.segment[0].nom)
                        let perfomed=false
                        if(((nbProjet/objectifNbprojet)*100).toFixed(2)>=100 ||
                            ((ca/objectifCa)*100).toFixed(2)>=100 ||
                            ((puissance/objectifPuissance)*100).toFixed(2)>=100
                        ){
                            perfomed=true
                        }
                        performedObjectif.push(perfomed)
                    })
                }


                const objectifsOptions = {
                    chart: {
                        type: 'bar',
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            columnWidth: '45%',
                        },
                    },
                    dataLabels: {
                        enabled: true,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent'],
                    },
                    xaxis: {
                        categories: objectifCategories,
                        axisBorder: {
                            show: false,
                        },
                    },
                    legend: {
                        show: true,
                    },
                    grid: {
                        row: {
                            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.2,
                        },
                        borderColor: '#f3f4f7',
                    },
                    tooltip: {
                        y: {
                            formatter: function(val) {
                                return 'Soit ' + val + " % de l'objectif";
                            },
                        },
                    },
                };

                const objectifsData = [
                    {
                        name: "Chiffre d'affaires",
                        data: caData,
                    },
                    {
                        name: 'Nombre de KWc',
                        data: puissanceData,
                    },
                    {
                        name: 'Nombre de projets',
                        data: nbProjetData,
                    },
                ];






                this.setState({
                    currentCA: currentCA,
                    totalCA: _.sum(currentCA),
                    lastCA: _.sum(lastCA),
                    averageCA: getAverage(currentCA,lastCA),
                    searchAllTasksByPeriod:allTasks,
                    searchPerformedTasksByPeriod:performedTasks,
                    searchDelayedTasksByPeriod:delayedTasks,
                    amountPendingOpportuinites:amountPendingOpportuinites,
                    performedOpp:searchConvertedOpportuniteByPeriod.length,
                    lostedOpp:searchLostedOpportuniteByPeriod.length,
                    currentOpp: currentOpp,
                    opportunitewithOffer:opportunitewithOffer,
                    totalOpp: _.sum(currentOpp),
                    lastOpp: _.sum(lastOpp),
                    averageOpp: getAverage(currentOpp,lastOpp),
                    segmentSales:segmentSales,
                    segmentSalesData:dataSegmentCA,
                    objectifsOptions:objectifsOptions,
                    objectifsData:objectifsData,
                    objectifCategories:objectifCategories,
                    performedObjectif:performedObjectif,
                });
            }


            ///////// stat opportunites////////////////////////////////////


            let searchByEnCours = [];
            if (this.state.enCours === true) {
                searchByEnCours = result.filter((tache) => tache.retard !== this.state.enCours);
            }

            let searchByRetard = [];
            if (this.state.retard === true) {
                searchByRetard = result.filter((tache) => tache.retard === this.state.retard);
            }
            let searchBySegment = [];

            if (this.state.segmentCibles !== undefined) {
                this.state.segmentCibles.map((segment) => {
                    if (
                        this.state['segment' + `${segment.nom}` + `${segment.id}`] !== undefined &&
                        this.state['segment' + `${segment.nom}` + `${segment.id}`]
                    ) {
                        searchBySegment = searchBySegment.concat(
                            result.filter((tache) => tache.opportuniteParent.segment.id === segment.id)
                        );
                    }
                });
                searchBySegment = _.uniqBy(searchBySegment, 'id');
            }
            let searchByAgence = [];

            if (this.state.agenceCibles !== undefined) {
                this.state.agenceCibles.map((agence) => {
                    if (
                        this.state['agence' + `${agence.nom}` + `${agence.id}`] !== undefined &&
                        this.state['agence' + `${agence.nom}` + `${agence.id}`]
                    ) {
                        searchByAgence = searchByAgence.concat(
                            result.filter((tache) => tache.opportuniteParent.agence.id === agence.id)
                        );
                    }
                });
                searchByAgence = _.uniqBy(searchByAgence, 'id');
            }
            let searchByVille = [];

            if (this.state.villeCibles !== undefined) {
                this.state.villeCibles.map((ville) => {
                    if (
                        this.state['ville' + `${ville.nom}` + `${ville.id}`] !== undefined &&
                        this.state['ville' + `${ville.nom}` + `${ville.id}`]
                    ) {
                        searchByVille = searchByVille.concat(
                            result.filter((tache) => tache.prospect.villeProspect.id === ville.id)
                        );
                    }
                });

                searchByVille = _.uniqBy(searchByVille, 'id');
            }
            let searchByAction = [];

            if (this.state.actionCibles !== undefined) {
                this.state.actionCibles.map((action) => {
                    if (
                        this.state['action' + `${action.nom}` + `${action.id}`] !== undefined &&
                        this.state['action' + `${action.nom}` + `${action.id}`]
                    ) {
                        searchByAction = searchByAction.concat(result.filter((tache) => tache.action.id === action.id));
                    }
                });

                searchByAction = _.uniqBy(searchByAction, 'id');
            }

            let searchByExecuteur = [];

            if (this.state.executeurCibles !== undefined) {
                this.state.executeurCibles.map((exec) => {
                    if (
                        this.state['executeur' + `${exec.nom}` + `${exec.id}`] !== undefined &&
                        this.state['executeur' + `${exec.nom}` + `${exec.id}`]
                    ) {
                        searchByExecuteur = searchByExecuteur.concat(
                            result.filter((tache) => tache.executeur.id === exec.id)
                        );
                    }
                });

                searchByExecuteur = _.uniqBy(searchByExecuteur, 'id');
            }

            let searchByDemandeur = [];

            if (this.state.demandeurCibles !== undefined) {
                this.state.demandeurCibles.map((dem) => {
                    if (
                        this.state['demandeur' + `${dem.nom}` + `${dem.id}`] !== undefined &&
                        this.state['demandeur' + `${dem.nom}` + `${dem.id}`]
                    ) {
                        searchByDemandeur = searchByDemandeur.concat(
                            result.filter((tache) => tache.demandeur.id === dem.id)
                        );
                    }
                });

                searchByDemandeur = _.uniqBy(searchByDemandeur, 'id');
            }

            let searchByAvancement = _.union(searchByEnCours, searchByRetard);

            let searchByUrgente = [];
            if (this.state.urgente === true) {
                searchByUrgente = result.filter((tache) => !tache.priorite.nom.includes('Normal'));
            }
            let searchByNormal = [];
            if (this.state.normal === true) {
                searchByNormal = result.filter((tache) => tache.priorite.nom.includes('Normal'));
            }
            let searchByUrgence = _.union(searchByUrgente, searchByNormal);

            let searchByReport = result;
            if (this.state.report !== true) {
                searchByReport = result.filter((tache) => tache.report === this.state.report);
            }

            /* if(keyword===undefined||keyword===null||keyword.length <1)
             {
                 searchByKey=result


             }
             else{
                 searchByKey = result.filter(
                     (tache) => tache.prospect.fullName.toLowerCase().includes(this.state.keyword.toLowerCase())===true
                         ||tache.prospect.tel1.toLowerCase().includes(this.state.keyword.toLowerCase())===true
                 )


             }
*/

            result = _.intersection(
                result,
                searchBySegment,
                searchByAgence,
                searchByAvancement,
                searchByAction,
                searchByReport,
                searchByUrgence,
                searchByVille,
                searchByExecuteur,
                searchByDemandeur
            );

            let nbEnCours = 0;
            let nbRetard = 0;
            let nbUrgente = 0;
            let nbNormal = 0;
            let nbReport = 0;

            let arr = [];

            result.map((res) => {
                if (res.retard) {
                    nbRetard++;
                } else {
                    nbEnCours++;
                }
                if (res.report) {
                    nbReport++;
                }
                if (res.priorite.nom.includes('Normal')) {
                    nbNormal++;
                } else {
                    nbUrgente++;
                }

                if (res.opportuniteParent.ca !== undefined && res.opportuniteParent.ca > 0)
                    arr.push(res.opportuniteParent.ca);

                this.state.segmentCibles.map((segment) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(segment.nom)}` + `${segment.id}`]: result.filter(
                                (tache) => tache.opportuniteParent.segment.id === segment.id
                            ).length,
                        },
                        function () {}
                    );
                });
                this.state.villeCibles.map((ville) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(ville.nom)}` + `${ville.id}`]: result.filter(
                                (tache) => tache.prospect.villeProspect.id === ville.id
                            ).length,
                        },
                        function () {}
                    );
                });
                this.state.agenceCibles.map((agence) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(agence.nom)}` + `${agence.id}`]: result.filter(
                                (tache) => tache.opportuniteParent.agence.id === agence.id
                            ).length,
                        },
                        function () {}
                    );
                });
                this.state.actionCibles.map((action) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(action.nom)}` + `${action.id}`]: result.filter(
                                (tache) => tache.action.id === action.id
                            ).length,
                        },
                        function () {}
                    );
                });
                this.state.executeurCibles.map((exec) => {
                    this.setState(
                        {
                            ['nbe' + `${Capitalize(exec.nom)}` + `${exec.id}`]: result.filter(
                                (tache) => tache.executeur.id === exec.id
                            ).length,
                        },
                        function () {}
                    );
                });
                this.state.demandeurCibles.map((demandeur) => {
                    this.setState(
                        {
                            ['nbd' + `${Capitalize(demandeur.nom)}` + `${demandeur.id}`]: result.filter(
                                (tache) => tache.demandeur.id === demandeur.id
                            ).length,
                        },
                        function () {}
                    );
                });
            });

            this.setState({
                result: result,
                nbEnCours: nbEnCours,
                nbRetard: nbRetard,
                nbReport: nbReport,
                nbUrgente: nbUrgente,
                nbNormal: nbNormal,
                test: arr,
            });
        }
    }

    customInputSwitchedEtat(e) {
        this.setState(
            {
                [`${e.target.name}`]: e.target.checked,
            },
            function () {}
        );
        this.searchByFilter(this.state.keyword);
    }
    customInputSwitched(e, obj, index) {
        this.setState(
            {
                [`${e.target.name}` + `${obj.nom}` + `${obj.id}`]: e.target.checked,
            },
            function () {}
        );
        this.searchByFilter(this.state.keyword);
    }

    togglePanel(panel) {
        var state = { ...this.state };
        state[panel] = !state[panel];
        this.setState(state);
    }
    search(e) {
        let result = this.state.taches.filter((tache) => tache.prospect.fullName.includes(e) === true);

        this.setState({
            result: result,
            keyword: e,
        });
    }
    handleTacheRetard(tache) {
        if (tache.retard !== true && tache.accompli !== true) {
            let data = {
                id: tache.id,
                retard: true,
            };
            this.props.updateTache(data);
        }
    }

    getTachesFiltre(role, idCommercial, idAgence, dateDebut, DateFin) {
        this.props.getTaches(role, idCommercial, idAgence, dateDebut, DateFin);
        this.setState({ role, idCommercial, idAgence, dateDebut, DateFin });
    }
    getAsyncData = async (actions) => {
        return new Promise((resolve) => {
            resolve(actions);
        });
    };
    getUserInfo = async (user) => {
        return new Promise((resolve) => {
            resolve(user);
        });
    };
    componentDidMount() {
        moment.locale('fr');
        this.props.getTaches('mesTaches', getLoggedInUser().id, '', '', '');
        this.props.getUsers('byId', '', getLoggedInUser().id)
        let opprtuniteFilter = '?owner.id=' + getLoggedInUser().id;
        this.props.getOpportunites(opprtuniteFilter);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.users!==this.props.users){
            this.getAsyncData(this.props.users).then((users) => {
                this.setState({
                    users:users
                },function () {

                    this.searchByFilter();
                })
            })

        }
        if (prevProps.taches !== this.props.taches) {



            this.getAsyncData(this.props.taches).then((taches) => {
                let result = this.searchByFilter();
                let tachesData = taches
                tachesData.map((tache, index) => {
                    tache.startDate = moment(tache.startDate).format('YYYY-MM-DD');
                });
                this.setState(
                    {
                        taches: tachesData,
                        result: tachesData,
                    },
                    function () {

                    }
                );

                let compt = 0;
                let segmentCibles = [];
                let villeCibles = [];
                let executeurCibles = [];
                let demandeurCibles = [];
                let agenceCibles = [];
                let actionCibles = [];
                let retard = false;
                let report = false;
                let urgente = false;
                let normal = false;
                let enCours = false;

                this.props.taches.map((tache, index) => {
                    segmentCibles.push({
                        id: tache.opportuniteParent.segment.id,
                        nom: tache.opportuniteParent.segment.nom,
                    });
                    villeCibles.push({ id: tache.prospect.villeProspect.id, nom: tache.prospect.villeProspect.ville });
                    executeurCibles.push({
                        id: tache.executeur.id,
                        nom: tache.executeur.commercial.fullName,
                        photo: tache.executeur.commercial.photo!==undefined?tache.executeur.commercial.photo.filePath:undefined,
                    });
                    demandeurCibles.push({
                        id: tache.demandeur.id,
                        nom: tache.demandeur.commercial.fullName,
                        photo: tache.demandeur.commercial.photo!==undefined?tache.demandeur.commercial.photo.filePath:undefined,
                    });
                    agenceCibles.push({
                        id: tache.opportuniteParent.agence.id,
                        nom: tache.opportuniteParent.agence.Nom,
                    });
                    actionCibles.push({ id: tache.action.id, nom: tache.action.nom });
                    if (tache.accompli !== true && tache.annulee !== true) {
                        if (
                            tache.retard === true ||
                            moment.duration(moment(`${tache.deadline}`).diff(moment().toDate(), 'milliSeconds'))
                        ) {
                            retard = true;
                        }
                        if (!tache.retard) {
                            enCours = true;
                        }
                        if (tache.report === true) {
                            report = true;
                        }
                        if (tache.priorite.nom.includes('Normal')) {
                            normal = true;
                        } else {
                            urgente = true;
                        }
                        compt = compt + 1;
                        this.setState({
                            compteurTache: compt,
                        });
                    }
                });
                segmentCibles = _.uniqBy(segmentCibles, 'id');
                villeCibles = _.uniqBy(villeCibles, 'id');
                villeCibles = _.orderBy(villeCibles, ['nom'], ['asc']);
                executeurCibles = _.uniqBy(executeurCibles, 'id');
                executeurCibles = _.orderBy(executeurCibles, ['nom'], ['asc']);
                demandeurCibles = _.uniqBy(demandeurCibles, 'id');
                demandeurCibles = _.orderBy(demandeurCibles, ['nom'], ['asc']);
                agenceCibles = _.uniqBy(agenceCibles, 'id');
                agenceCibles = _.orderBy(agenceCibles, ['nom'], ['asc']);
                actionCibles = _.uniqBy(actionCibles, 'id');
                actionCibles = _.orderBy(actionCibles, ['nom'], ['asc']);
                this.setState({
                    segmentCibles: segmentCibles,
                    villeCibles: villeCibles,
                    executeurCibles: executeurCibles,
                    demandeurCibles: demandeurCibles,
                    agenceCibles: agenceCibles,
                    actionCibles: actionCibles,
                    report: report,
                    urgente: urgente,
                    retard: retard,
                    normal: normal,
                    enCours: enCours,
                });
                villeCibles.map((ville) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(ville.nom)}` + `${ville.id}`]: 0,
                            ['ville' + `${ville.nom}` + `${ville.id}`]: true,
                        },
                        function () {}
                    );
                });
                segmentCibles.map((seg) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(seg.nom)}` + `${seg.id}`]: 0,
                            ['segment' + `${seg.nom}` + `${seg.id}`]: true,
                        },
                        function () {}
                    );
                });
                agenceCibles.map((agence) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(agence.nom)}` + `${agence.id}`]: 0,
                            ['agence' + `${agence.nom}` + `${agence.id}`]: true,
                        },
                        function () {}
                    );
                });
                actionCibles.map((action) => {
                    this.setState(
                        {
                            ['nb' + `${Capitalize(action.nom)}` + `${action.id}`]: 0,
                            ['action' + `${action.nom}` + `${action.id}`]: true,
                        },
                        function () {}
                    );
                });
                executeurCibles.map((exec) => {
                    this.setState(
                        {
                            ['nbe' + `${Capitalize(exec.nom)}` + `${exec.id}`]: 0,
                            ['executeur' + `${exec.nom}` + `${exec.id}`]: true,
                        },
                        function () {}
                    );
                });
                demandeurCibles.map((demandeur) => {
                    this.setState(
                        {
                            ['nbd' + `${Capitalize(demandeur.nom)}` + `${demandeur.id}`]: 0,
                            ['demandeur' + `${demandeur.nom}` + `${demandeur.id}`]: true,
                        },
                        function () {}
                    );
                });

                this.searchByFilter();
            });
            const allKeys = _.filter(this.props.taches, { id: 44 });
        }
        if (prevProps.opportunites !== this.props.opportunites) {
            this.getAsyncData(this.props.opportunites).then((opportunites) => {
                let opportunitesData = opportunites;
                opportunitesData.map((opportunite, index) => {
                    opportunite.startDate = moment(opportunite.startDate).format('YYYY-MM-DD');
                });

                this.setState(
                    {
                        opportunites: opportunitesData,
                    },
                    function () {
                        this.searchByFilter();
                    }
                );


            });


        }
    }

    render() {



        return this.state.performedObjectif===undefined||this.state.objectifCategories===undefined||this.state.segmentSales === undefined || this.state.opportunites === undefined ||this.state.taches === undefined || this.state.test === undefined ? (
            <Loader></Loader>
        ) : (
            <React.Fragment>

                <div className="">
                    <Row className="page-title align-items-center">
                        <Col sm={4} xl={6}>
                            <h4 className="mb-1 mt-0">Dashboard</h4>
                        </Col>
                        <Col sm={8} xl={6}>
                            <form className="form-inline float-sm-right mt-3 mt-sm-0">
                                <Flatpickr
                                    value={this.state.filterDate}
                                    style={{ width: '400px' }}
                                    options={{
                                        locale: French,
                                        enableTime: false,
                                        altInput: true,
                                        dateFormat: 'Y/m/d',
                                        altFormat: 'd/m/y',
                                        allowInput: true,
                                        mode: 'range',
                                    }}
                                    onChange={(date) => {
                                        this.setState({ filterDate: date });
                                    }}
                                    className="form-control"
                                />
                                {/*<Button className="btn-block btn btn-success mt-1 mb-1 float-left" id="group1" href="#" onClick={() => { this.togglePanel('panel1') }}>




                                                <i className='uil uil-flask '> </i>Filtres





                                            </Button>
*/}
                                <Button
                                    color={'primary'}
                                    className={'ml-2'}
                                    id="group1"
                                    href="#"
                                    /*onClick={() => { this.togglePanel('panel1') }}*/

                                    onClick={() => {
                                        this.searchByFilter();
                                    }}>
                                    <i className="uil uil-calender "> </i>Changer la periode
                                </Button>
                                {/* <UncontrolledButtonDropdown>
                                    <DropdownToggle color="primary" className="dropdown-toggle">
                                        <i className='uil uil-file-alt mr-1'></i>Download
                                            <i className="icon ml-1"><ChevronDown /></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>
                                            <Mail className="icon-dual icon-xs mr-2"></Mail>
                                            <span>Email</span>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Printer className="icon-dual icon-xs mr-2"></Printer>
                                            <span>Print</span>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>
                                            <File className="icon-dual icon-xs mr-2"></File>
                                            <span>Re-Generate</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>*/}
                            </form>
                        </Col>
                        <Collapse isOpen={this.state.panel1}>
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <FormGroup className={'filtre'}>
                                            <Label
                                                for="exampleCheckbox"
                                                className="med-gradient pl-2 "
                                                style={{ color: '#fff', width: '100%' }}>
                                                Actions
                                            </Label>
                                            <div>
                                                {this.state.actionCibles.map((action, index) => {
                                                    return (
                                                        <CustomInput
                                                            key={index}
                                                            type="checkbox"
                                                            name={'action'}
                                                            id={action.id}
                                                            label={
                                                                action.nom +
                                                                ' (' +
                                                                this.state[
                                                                    'nb' + `${Capitalize(action.nom)}` + `${action.id}`
                                                                ] +
                                                                ')'
                                                            }
                                                            inline
                                                            onChange={(e) => this.customInputSwitched(e, action, index)}
                                                            checked={
                                                                this.state['action' + `${action.nom}` + `${action.id}`]
                                                            }
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </FormGroup>
                                    </Col>

                                    <Col lg={4}>
                                        <FormGroup className={'filtre'}>
                                            <Label
                                                for="exampleCheckbox "
                                                className="med-gradient  pl-2 "
                                                style={{ color: '#fff', width: '100%' }}>
                                                Avancement
                                            </Label>
                                            <div>
                                                <CustomInput
                                                    type="checkbox"
                                                    id="enCours"
                                                    name="enCours"
                                                    label={'En Cours (' + this.state.nbEnCours + ')'}
                                                    inline
                                                    onChange={(e) => this.customInputSwitchedEtat(e)}
                                                    checked={this.state.enCours}
                                                />
                                                <CustomInput
                                                    type="checkbox"
                                                    id="retard"
                                                    name="retard"
                                                    label={'Expirées (' + this.state.nbRetard + ')'}
                                                    inline
                                                    onChange={(e) => this.customInputSwitchedEtat(e)}
                                                    checked={this.state.retard}
                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup className={'filtre'}>
                                            <Label
                                                for="exampleCheckbox"
                                                className="med-gradient  pl-2"
                                                style={{ color: '#fff', width: '100%' }}>
                                                Urgence
                                            </Label>
                                            <div>
                                                <CustomInput
                                                    type="checkbox"
                                                    id="report"
                                                    name="report"
                                                    label={'Reportées (' + this.state.nbReport + ')'}
                                                    inline
                                                    onChange={(e) => this.customInputSwitchedEtat(e)}
                                                    checked={this.state.report}
                                                />

                                                <CustomInput
                                                    type="checkbox"
                                                    id="normal"
                                                    name="normal"
                                                    label={'Normales (' + this.state.nbNormal + ')'}
                                                    inline
                                                    onChange={(e) => this.customInputSwitchedEtat(e)}
                                                    checked={this.state.normal}
                                                />
                                                <CustomInput
                                                    type="checkbox"
                                                    id="urgente"
                                                    name="urgente"
                                                    label={'Urgentes (' + this.state.nbUrgente + ')'}
                                                    inline
                                                    onChange={(e) => this.customInputSwitchedEtat(e)}
                                                    checked={this.state.urgente}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup className={'filtre'}>
                                            <Label
                                                for="exampleCheckbox"
                                                className="med-gradient  pl-2"
                                                style={{ color: '#fff', width: '100%' }}>
                                                Segments
                                            </Label>
                                            <div>
                                                {this.state.segmentCibles.map((segment, index) => {
                                                    return (
                                                        <CustomInput
                                                            key={index}
                                                            type="checkbox"
                                                            name="segment"
                                                            id={segment.id}
                                                            label={
                                                                segment.nom +
                                                                ' (' +
                                                                this.state[
                                                                    'nb' +
                                                                        `${Capitalize(segment.nom)}` +
                                                                        `${segment.id}`
                                                                ] +
                                                                ')'
                                                            }
                                                            inline
                                                            onChange={(e) =>
                                                                this.customInputSwitched(e, segment, index)
                                                            }
                                                            checked={
                                                                this.state[
                                                                    'segment' + `${segment.nom}` + `${segment.id}`
                                                                ]
                                                            }
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </FormGroup>
                                        <FormGroup className={'filtre'}>
                                            <Label
                                                for="agenceCheckbox"
                                                className="med-gradient  pl-2"
                                                style={{ color: '#fff', width: '100%' }}>
                                                Agences
                                            </Label>
                                            <div>
                                                {this.state.agenceCibles.map((agence, index) => {
                                                    return (
                                                        <CustomInput
                                                            key={index}
                                                            type="checkbox"
                                                            name="agence"
                                                            id={agence.id}
                                                            label={
                                                                agence.nom +
                                                                ' (' +
                                                                this.state[
                                                                    'nb' + `${Capitalize(agence.nom)}` + `${agence.id}`
                                                                ] +
                                                                ')'
                                                            }
                                                            inline
                                                            onChange={(e) => this.customInputSwitched(e, agence, index)}
                                                            checked={
                                                                this.state['agence' + `${agence.nom}` + `${agence.id}`]
                                                            }
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup className={'filtre'}>
                                            <Label
                                                for="exampleCheckbox"
                                                className="med-gradient  pl-2"
                                                style={{ color: '#fff', width: '100%' }}>
                                                Villes
                                            </Label>
                                            <div>
                                                {this.state.villeCibles.map((ville, index) => {
                                                    return (
                                                        <CustomInput
                                                            key={index}
                                                            type="checkbox"
                                                            name={'ville'}
                                                            id={ville.id}
                                                            label={
                                                                ville.nom +
                                                                ' (' +
                                                                this.state[
                                                                    'nb' + `${Capitalize(ville.nom)}` + `${ville.id}`
                                                                ] +
                                                                ')'
                                                            }
                                                            inline
                                                            onChange={(e) => this.customInputSwitched(e, ville, index)}
                                                            checked={
                                                                this.state['ville' + `${ville.nom}` + `${ville.id}`]
                                                            }
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <FormGroup className={'filtre'}>
                                            <Label
                                                for="exampleCheckbox"
                                                className="med-gradient  pl-2"
                                                style={{ color: '#fff', width: '100%' }}>
                                                Executeurs
                                            </Label>
                                            <div>
                                                {this.state.executeurCibles.map((exec, index) => {
                                                    return (

                                                            <CustomInput
                                                                key={index}
                                                                type="checkbox"
                                                                name={'executeur'}
                                                                id={exec.id}
                                                                inline
                                                                onChange={(e) =>
                                                                    this.customInputSwitched(e, exec, index)
                                                                }
                                                                checked={
                                                                    this.state[
                                                                        'executeur' + `${exec.nom}` + `${exec.id}`
                                                                    ]
                                                                }>
                                                                <img
                                                                    src={
                                                                        exec.photo === undefined
                                                                            ? avatar
                                                                            : SERVERUIMAGESUSERS + exec.photo
                                                                    }
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle mr-2 "
                                                                />
                                                                {exec.nom +
                                                                    ' (' +
                                                                    this.state[
                                                                        'nbe' + `${Capitalize(exec.nom)}` + `${exec.id}`
                                                                    ] +
                                                                    ')'}
                                                            </CustomInput>

                                                    );
                                                })}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup className={'filtre'}>
                                            <Label
                                                for="exampleCheckbox"
                                                className="med-gradient  pl-2"
                                                style={{ color: '#fff', width: '100%' }}>
                                                Demandeurs
                                            </Label>
                                            <div>
                                                {this.state.demandeurCibles.map((demandeur, index) => {
                                                    return (

                                                            <CustomInput
                                                                key={index}
                                                                type="checkbox"
                                                                name={'demandeur'}
                                                                id={demandeur.id}
                                                                inline
                                                                onChange={(e) =>
                                                                    this.customInputSwitched(e, demandeur, index)
                                                                }
                                                                checked={
                                                                    this.state[
                                                                        'demandeur' +
                                                                            `${demandeur.nom}` +
                                                                            `${demandeur.id}`
                                                                    ]
                                                                }>
                                                                <img
                                                                    src={
                                                                        demandeur.photo === undefined
                                                                            ? avatar
                                                                            : SERVERUIMAGESUSERS + demandeur.photo
                                                                    }
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle mr-2 "
                                                                />
                                                                {demandeur.nom +
                                                                    ' (' +
                                                                    this.state[
                                                                        'nbd' +
                                                                            `${Capitalize(demandeur.nom)}` +
                                                                            `${demandeur.id}`
                                                                    ] +
                                                                    ')'}
                                                            </CustomInput>

                                                    );
                                                })}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button
                                    className="btn-block btn btn-soft-primary mt-1 mb-1"
                                    id="group1"
                                    href="#"
                                    onClick={() => {
                                        this.togglePanel('panel1');
                                    }}>
                                    <i className="uil uil-flask "> </i>
                                    Masquer les filtres
                                </Button>
                            </CardBody>
                        </Collapse>
                    </Row>



                    <Row>
                        <Col md={6} xl={4}>
                            <StatisticsChartWidget2
                                name="Toutes"
                                type="area"
                                title={_.sum(this.state.searchAllTasksByPeriod) + " Demandées"}
                                subtitle="Tâches"
                                colors={['#5369f8']}
                                data={this.state.searchAllTasksByPeriod}>

                            </StatisticsChartWidget2>
                        </Col>
                        <Col md={6} xl={4}>
                            <StatisticsChartWidget2
                                name="Accomplies"
                                type="area"
                                title={_.sum(this.state.searchPerformedTasksByPeriod) + " Accomplies"}
                                subtitle="Tâches"
                                colors={['#43d39e']}
                                data={this.state.searchPerformedTasksByPeriod}>

                            </StatisticsChartWidget2>
                        </Col>
                        <Col md={6} xl={4}>
                            <StatisticsChartWidget2
                                name="En retard"
                                type="area" title={_.sum(this.state.searchDelayedTasksByPeriod) + " Expirées"}
                                subtitle="Tâches"
                                colors={['#f77e53']}
                                data={this.state.searchDelayedTasksByPeriod}>

                            </StatisticsChartWidget2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xl={3}>
                            <StatisticsChartWidget
                                description="Chiffre Affaire"
                                title={_.sum(this.state.currentCA) + ' TND'}
                                data={this.state.currentCA}
                                trend={
                                    this.state.averageCA > 0
                                        ? {
                                            textClass: 'text-success',
                                            icon: 'uil uil-arrow-up',
                                            value: this.state.averageCA + '%',
                                        }
                                        : this.state.averageCA < 0
                                        ? {
                                            textClass: 'text-danger',
                                            icon: 'uil uil-arrow-down',
                                            value: this.state.averageCA + '%',
                                        }
                                        : {
                                            textClass: 'text-warn',
                                            icon: 'uil uil-sunset',
                                            value: '--' + ' NA',
                                        }
                                }
                            />
                        </Col>
                        <Col md={6} xl={3}>
                            <StatisticsChartWidget
                                description="Opportunites"
                                title={_.sum(this.state.currentOpp)}
                                data={this.state.currentOpp}
                                colors={['#f77e53']}
                                trend={
                                    this.state.averageOpp > 0
                                        ? {
                                            textClass: 'text-success',
                                            icon: 'uil uil-arrow-up',
                                            value: this.state.averageOpp + '%',
                                        }
                                        : this.state.averageOpp < 0
                                        ? {
                                            textClass: 'text-danger',
                                            icon: 'uil uil-arrow-down',
                                            value: this.state.averageOpp + '%',
                                        }
                                        : {
                                            textClass: 'text-warn',
                                            icon: 'uil uil-sunset',
                                            value: '--' + ' NA',
                                        }
                                }
                            />
                        </Col>

                        <Col md={6} xl={3}>
                            <StatisticsWidget description="Taux de conversion"
                                              title={
                                                  ((this.state.performedOpp/_.sum(this.state.currentOpp))*100).toFixed(2)+ "% P"
                                                  + " - " +((this.state.performedOpp/this.state.opportunitewithOffer)*100).toFixed(2)+ "% O"
                                              }
                                              icon={Briefcase} iconClass="icon-dual-success">

                            </StatisticsWidget>
                        </Col>

                        <Col md={6} xl={3}>
                            <StatisticsWidget description="Offres en cours"
                                              title={
                                                  this.state.amountPendingOpportuinites+ " TND"
                                              }
                                              icon={Briefcase} iconClass="icon-dual-danger">

                            </StatisticsWidget>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardBody className="">
                                    <h5 className="card-title mt-0 mb-0 header-title">Ventes par segment</h5>

                                    <Chart
                                        options={this.state.segmentSales}
                                        series={this.state.segmentSalesData}
                                        type="donut"
                                        className="apex-charts mb-0 mt-4"
                                        height={302}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6}>
                            <Card>
                                <CardBody className="pb-0">
                                    <h5 className="card-title header-title">Objectifs</h5>
                                    <div className="float-right" style={{height:"30px"}}>

                                    {this.state.objectifCategories.map((objectif,index)=>{
                                      return(
                                          <div key={index} className="float-left mr-3">
                                              {this.state.performedObjectif[index]

                                                  ?<CheckCircle className="icon-xs icon-dual-success font-size-11 mr-1"></CheckCircle>
                                                  :<XCircle className="icon-xs icon-dual-danger font-size-11 mr-1"></XCircle>

                                              }
                                              <span className="text-muted mt-1"  >{objectif}</span>
                                          </div>


                                      )
                                    })}
                                    </div>

                                    <Chart options={this.state.objectifsOptions} series={this.state.objectifsData} type="bar" className="apex-charts mt-3" height={330} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>




                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    /*    getSegments,
    getCanaux,
    getSelectedCanal,
    getSelectedVille,
    getVilles,
    setProspect,
    getProspects,
    affectProspect,*/
    getTaches,
    getOpportunites,
    getUsers,
    /*,
    getTodayOpportunites,
    getIncomingOpportunites,
    getOthersOpportunites,
    updateTache*/
};

const mapStateToProps = (state) => ({

    taches: state.Tache.taches,
    opportunites: state.Opportunite.opportunites,
    users: state.Crm.user,
    /* expiredTaches: state.Tache.expiredTaches,*/
});

Dashboard = connect(mapStateToProps, mapDispatchToProps, null)(Dashboard);
export default Dashboard;
