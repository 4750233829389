/* AUTH */
export const GET_VILLES = 'GET_VILLES';
export const VILLES_RECEIVED = 'VILLES_RECEIVED';




export const GET_USERS = 'GET_USERS';
export const USERS_RECEIVED = 'USERS_RECEIVED';
export const SET_VILLE_POSITION = 'SET_VILLE_POSITION';
export const VILLE_POSITION_RECEIVED = 'VILLE_POSITION_RECEIVED';
export const SET_MARKER_POSITION = 'SET_MARKER_POSITION';
export const MARKER_POSITION_RECEIVED = 'MARKER_POSITION_RECEIVED';

export const INSERT_AGENCE = 'INSERT_AGENCE';
export const AGENCE_ADDED = 'AGENCE_ADDED';
export const SET_SEGMENT = 'SET_SEGMENT';
export const SEGMENT_ADDED = 'SEGMENT_ADDED';
export const SET_USER = 'SET_USER';
export const USER_ADDED = 'USER_ADDED'
export const ADD_FAILURE = 'ADD_FAILURE'

export const UPDATE_USER = 'UPDATE_USER';
export const USER_UPDATED = 'USER_UPDATED';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const SEGMENT_REQUEST_FAILED = 'SEGMENT_REQUEST_FAILED';

export const GET_SEGMENTS = 'GET_SEGMENTS';
export const SEGMENTS_RECEIVED = 'SEGMENTS_RECEIVED';

export const PATCH_SEGMENT = 'PATCH_SEGMENT';
export const SEGMENT_PATCHED = 'SEGMENT_PATCHED';

export const DELETE_SEGMENT = 'DELETE_SEGMENT';
export const SEGMENT_DELETED = 'SEGMENT_DELETED';
export const SET_COMMERCIAUX_AGENCE = 'SET_COMMERCIAUX_AGENCE';
export const COMMERCIAUX_AGENCE_ADDED = 'COMMERCIAUX_AGENCE_ADDED';

export const GET_CANAUX = 'GET_CANAUX';
export const CANAUX_RECEIVED = 'CANAUX_RECEIVED';
export const SELECTED_CANAL = 'SELECTED_CANAL';
export const RECEIVED_SELECTED_CANAL = 'RECEIVED_SELECTED_CANAL';

export const SELECTED_VILLE = 'SELECTED_VILLE';
export const RECEIVED_SELECTED_VILLE = 'RECEIVED_SELECTED_VILLE';
export const GET_ACTION = 'GET_ACTION';
export const ACTION_RECEIVED = 'ACTION_RECEIVED';


export const GET_ETAT = 'GET_ETAT';
export const ETAT_RECEIVED = 'ETAT_RECEIVED';

export const GET_CATEGORIE = 'GET_CATEGORIE';
export const CATEGORIE_RECEIVED = 'CATEGORIE_RECEIVED';

export const PLANIFIED_ACTION_RECEIVED = 'PLANIFIED_ACTION_RECEIVED';
export const PLANIFIED_ACTION = 'PLANIFIED_ACTION';


export const SELECTED_SEGMENT = 'SELECTED_SEGMENT';
export const RECEIVED_SELECTED_SEGMENT = 'RECEIVED_SELECTED_SEGMENT';


export const SELECTED_ACTION = 'SELECTED_ACTION';
export const RECEIVED_SELECTED_ACTION = 'RECEIVED_SELECTED_ACTION';

export const SELECTED_PRIORITE = 'SELECTED_PRIORITE';
export const RECEIVED_SELECTED_PRIORITE = 'RECEIVED_SELECTED_PRIORITE';

export const SELECTED_USER= 'SELECTED_USER';
export const RECEIVED_SELECTED_USER = 'RECEIVED_SELECTED_USER';

export const CRITERES_SEGMENT_RECEIVED = 'CRITERES_SEGMENT_RECEIVED';
export const CRITERES_SEGMENT = 'CRITERES_SEGMENT';
export const GET_FILE_BY_NAME = 'GET_FILE_BY_NAME';
export const FILE_RECIVED = 'FILE_RECIVED';


export const GET_FILES = 'GET_FILES';
export const FILES_RECEIVED = 'FILES_RECEIVED';


export const ADD_REFERENCE = 'ADD_REFERENCE';
export const REFERENCE_ADDED = 'REFERENCE_ADDED';


export const UPDATE_REFERENCE = 'UPDATE_REFERENCE';
export const REFERENCE_UPDATED = 'REFERENCE_UPDATED';



export const GET_CAUSE_PERTE = 'GET_CAUSE_PERTE';
export const CAUSE_PERTE_RECEIVED = 'CAUSE_PERTE_RECEIVED';

export const SET_FCM_TOKEN = 'SET_FCM_TOKEN';
export const FCM_TOKEN_ADDED = 'FCM_TOKEN_ADDED';


export const GET_FCM_TOKEN = 'GET_FCM_TOKE';
export const FCM_TOKEN_RECEIVED = 'FCM_TOKEN_RECEIVED';

/*

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const FILE_UPLOADED = 'FILE_UPLOADED';*/
