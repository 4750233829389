import React from 'react';
import { connect } from 'react-redux';
import { getUsers, setCommerciauxAgence} from '../redux/crm/actions';
import {AvField} from "availity-reactstrap-validation";
import Select from "react-select";
import {CardBody} from "reactstrap";
import UsersSelectSingle from "./UsersSelectSingle";
import {setAgence,getAgences,getSelectedAgence} from "../redux/crm/agence/actions";
import Loader from "./Loader";
let oldUsers=[]
class UsersSelectMultiple extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            users:[],

        }

      this.handleChange = this.handleChange.bind(this);
      this.handleChangeChild = this.handleChangeChild.bind(this);
    }

    handleChangeChild(e) {
        console.log("eeeeeeeee",e)

            if(e.target.options[e.nativeEvent.target.selectedIndex].attributes!==undefined){
                if (e.target.options[e.nativeEvent.target.selectedIndex].attributes.value !== undefined) {
                    let value = e.target.options[e.nativeEvent.target.selectedIndex].attributes.value!==undefined?e.target.options[e.nativeEvent.target.selectedIndex].attributes.value.value:null
                    let label =e.target.options[e.nativeEvent.target.selectedIndex].attributes.label!==undefined? e.target.options[e.nativeEvent.target.selectedIndex].attributes.label.value:null

                    // alert(e.target.name)
                    let name=e.target.name
                    if(value!==null&&label!==null){
                        this.setState((prevState) => ({
                            selectedUsers: {

                                ...prevState.selectedUsers,

                                [`${name}`]: {
                                    value:value,
                                    label:label
                                },
                            },
                        }),function () {
                            console.log("value commerciaux", this.state.selectedUsers  )
                            this.props.setCommerciauxAgence(this.state.selectedUsers)
                        });
                    }
                    else{
                        this.setState((prevState) => ({
                            selectedUsers: {

                                ...prevState.selectedUsers,

                                [`${name}`]: null
                            },
                        }),function () {
                            console.log("value", this.state.selectedUsers  )
                            this.props.setCommerciauxAgence(this.state.selectedUsers)
                        });
                    }


                }
            }



    }



    userExists(arr,value) {
        return arr.some(function(el) {
            return el.value === value;
        });
    }
    handleChange=(event,values,list)=>{



        let arr = [];
        if(values.action==="select-option")
        {
            values.option=event
            let commercial=event
            this.setState({commerciaux:event},function () {
                console.log("ajout commercial state change....",this.state)
            })

            this.setState((prevState) => ({
                selectedUsers: {

                    ...prevState.selectedUsers,

                    allUsers:event,
                },
            }),function () {
                this.props.setCommerciauxAgence(this.state.selectedUsers)
                console.log("event selected user state change....",this.state.selectedUsers)
            })


        }
        if(values.action==="remove-value")
        {
            values.option=event

            oldUsers=this.state.selectedUsers
            let usersToUpdate=[]


            this.setState((prevState) => ({
                selectedUsers: {
                    ...prevState.selectedUsers,
                   allUsers:event,
                },
            }),function () {

                let chefAgence=this.state.selectedUsers.chefAgence;
                let coordinateurAgence=this.state.selectedUsers.coordinateurAgence
                let agentSteg=this.state.selectedUsers.agentSteg
                if(this.state.selectedUsers.allUsers!==null)
                {
                    if(chefAgence&&(this.userExists(this.state.selectedUsers.allUsers,chefAgence.value)===false))
                    {
                        this.setState((prevState) => ({
                            selectedUsers: {
                                ...prevState.selectedUsers,
                                allUsers:event,
                                chefAgence:null
                            },
                        }),function () {


                            this.props.setCommerciauxAgence(this.state.selectedUsers)
                            console.log("event selected chef agence....",this.state.selectedUsers)
                        });
                    }
                    if(coordinateurAgence!==null&&(this.userExists(this.state.selectedUsers.allUsers,coordinateurAgence.value)===false))
                    {
                        this.setState((prevState) => ({
                            selectedUsers: {

                                ...prevState.selectedUsers,
                                allUsers:event,
                                coordinateurAgence: null
                            },
                        }),function () {
                            console.log("event selected coordinateur", this.state.selectedUsers  )
                            this.props.setCommerciauxAgence(this.state.selectedUsers)
                        });
                    }
                    if(agentSteg!==null&&(this.userExists(this.state.selectedUsers.allUsers,agentSteg.value)===false))
                    {
                        this.setState((prevState) => ({
                            selectedUsers: {

                                ...prevState.selectedUsers,
                                allUsers:event,
                                agentSteg: null
                            },
                        }),function () {

                        });
                    }
                    console.log("event selected tous les commerciaux", this.state.selectedUsers  )
                    this.props.setCommerciauxAgence(this.state.selectedUsers)
                }
                else{
                    this.setState((prevState) => ({
                        selectedUsers: {
                            ...prevState.selectedUsers,

                            agentSteg: null,
                            chefAgence:null,
                            coordinateurAgence:null
                        },
                    }),function () {
                        console.log("value commerciaux", this.state.selectedUsers  )
                        this.props.setCommerciauxAgence(this.state.selectedUsers)
                    });
                }
            });
        }
       ///modification des autres states
    }

    getUsers = async (user) => {
        return new Promise((resolve) => {
            resolve(user);
        });
    };

    componentDidMount() {

        let role = this.props.role
        let selectName=this.props.name
        this.getUsers(this.props.getUsers(role,'',this.props.id!==undefined?this.props.id:"")) /// this.props.users
        this.getUsers(this.props.getUsers(selectName,'',this.props.id!==undefined?this.props.id:undefined)) //this.props.selectedCommerciaux dans la base de données
      //  alert(this.props.id)

    }
    componentDidUpdate(prevProps, prevState, snapshot) {


        if(prevProps.agence!==this.props.agence){
            alert("yes")
         }
        if(prevProps.selectedUsers!==this.props.selectedUsers){

            console.log("selCommmmmm",this.props.selectedUsers)
            //// dans le cas de modification d'une agence
            if(this.props.id!==undefined){
                this.getUsers(this.props.agence).then((agence) => {


                    let arr=[]
                    agence.commerciauxAgence.map((commercial) => {
                        arr.push({value:commercial.id,label:commercial.prenom +" "+ commercial.Nom })

                    });

                    this.setState({
                        selectedUsers:{
                            allUsers:arr,
                            chefAgence:this.props.agence.chefAgence===null||this.props.agence.chefAgence===undefined?null:
                                {
                                    value:this.props.agence.chefAgence.id,
                                    label:this.props.agence.chefAgence.prenom +" "+ this.props.agence.chefAgence.Nom
                                },
                            coordinateurAgence:this.props.agence.coordinateurAgence===null||this.props.agence.coordinateurAgence===undefined?null:
                                {
                                    value:this.props.agence.coordinateurAgence.id,
                                    label:this.props.agence.coordinateurAgence.prenom +" "+ this.props.agence.coordinateurAgence.Nom
                                },
                            agentSteg:this.props.agence.agentSteg===null||this.props.agence.agentSteg===undefined?null:
                                {
                                    value:this.props.agence.agentSteg.id,
                                    label:this.props.agence.agentSteg.prenom +" "+ this.props.agence.agentSteg.Nom
                                },
                        }
                    },function () {
                        this.props.setCommerciauxAgence(this.state.selectedUsers)
                        console.log("selected Users from begin", this.state.selectedUsers)
                    })


                });
            }
        else{
                this.setState({
                    selectedUsers:{
                        allUsers:null,
                        chefAgence:null,
                        coordinateurAgence:null,
                        agentSteg:null,
                    }
                },function () {
                  //  this.props.setCommerciauxAgence(this.state.selectedUsers)
                    console.log("selected Users from begin", this.state.selectedUsers)
                })
            }









        }
        if(prevProps.agenceCommerciaux!==this.props.agenceCommerciaux){
            //recuperation liste descommerciaux
            console.log("update selected Users", this.props.agenceCommerciaux)
        }

        if(prevProps.users!==this.props.users){
            console.log("commmmmmmmmmm",this.props.users)
        }

    }
    removeDuplicates(users) {
        let pp = users.filter( (ele, ind) => ind === users.findIndex( elem => elem.id === ele.id ))
        return pp
    }



    expose_users =({users,selectedUsers})=> (

        users&&selectedUsers ?

            <React.Fragment>

                          <Select
                    name={this.props.name}
                    id={this.props.id}
                    helpMessage={this.props.helpMessage}
                    onChange={(event,values)=>this.handleChange(event,values)}
                    value={this.state.selectedUsers.allUsers}
                    className="react-select"
                    classNamePrefix="react-select"
                    isMulti={true}
                         options = {
                        this.removeDuplicates(users.concat(selectedUsers)).map(d => ({
                            "value" : d.id,
                            "label" : d. prenom +" "+ d.Nom }))

                    }>



                </Select>




                <AvField  type="select" name="chefAgence" label="Chef de l'agence" helpMessage="Veuillez choisir le chef de l'agence"
                          onChange={this.handleChangeChild}
                          value = {this.state.selectedUsers!==undefined&&this.state.selectedUsers.chefAgence!==null?this.state.selectedUsers.chefAgence.value:null}
                >
                    {
                        this.state.selectedUsers.allUsers===undefined||this.state.selectedUsers.allUsers===null?
                            <option key={-1} value={null} label={null}>Veuillez affecter des commerciaux</option>
                            :<React.Fragment>
                                <option key={-1} value={0}>Veuillez choisir un commercial</option>
                                { this.state.selectedUsers.allUsers.map((user,index)=>
                                <option key={index} value={user.value} label={user.label}> {user.label}  </option>
                                )}
                            </React.Fragment>
                    }
                </AvField>

                <AvField  type="select" name="coordinateurAgence" label="Coordinateur" helpMessage="Veuillez choisir un coordinateur"
                          onChange={this.handleChangeChild}
                          value = {this.state.selectedUsers!==undefined&&this.state.selectedUsers.coordinateurAgence!==null?this.state.selectedUsers.coordinateurAgence.value:null}
                          >
                    {
                        this.state.selectedUsers.allUsers===undefined||this.state.selectedUsers.allUsers===null?
                            <option >Veuillez affecter des commerciaux</option>

                            :<React.Fragment>
                                <option key={-1} value={0}>Veuillez choisir un commercial</option>
                                { this.state.selectedUsers.allUsers.map((user,index)=>
                                    <option key={index} value={user.value} label={user.label}>{user.label}  </option>
                                )}
                            </React.Fragment>
                    }
                </AvField>

                <AvField  type="select" name="agentSteg" label="Chargé dossiers STEG" helpMessage="Veuillez choisir un chargé"
                          value = {this.state.selectedUsers!==undefined&&this.state.selectedUsers.agentSteg!==null?this.state.selectedUsers.agentSteg.value:null}
                          onChange={this.handleChangeChild} >
                    {

                        this.state.selectedUsers.allUsers===undefined||this.state.selectedUsers.allUsers===null?
                            <option>Veuillez affecter des commerciaux</option>
                            :<React.Fragment>
                                <option key={-1} value={0}>Veuillez choisir un commercial</option>
                                {this.state.selectedUsers.allUsers.map((user,index)=>
                                    <option key={index} value={user.value} label={user.label}>{user.label}  </option>
                                )}
                            </React.Fragment>
                    }
                </AvField>
            </React.Fragment>

        :null
);

render(){
return(

    this.state.selectedUsers === undefined  ?
            <Loader></Loader>
        :

                   <div>

                       <p>{this.props.label}</p>
                       {this.expose_users(this.props)}
                  </div>



)
}


}

const mapDispatchToProps = {
getUsers,setCommerciauxAgence,getAgences
};
const mapStateToProps = (state) => ({
 users: state.Crm.commerciaux,
 selectedUsers: state.Crm.selectedCommerciaux,
 agenceCommerciaux:state.Crm.agenceCommerciaux,
 agence: state.Agence.agences,
});
UsersSelectMultiple = connect(mapStateToProps,mapDispatchToProps ,null)(UsersSelectMultiple)

export default UsersSelectMultiple;
