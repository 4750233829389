// @flow

import { put,take,takeLatest,all,call, takeEvery,takeLeading } from 'redux-saga/effects';
import { fetchJSON ,insertJSON,deleteJSON,SERVERURI,TOKEN} from '../../../helpers/api';
import {SET_OPPORTUNITE,OPPORTUNITE_ADDED,OPPORTUNITE_REQUEST_FAILED,OPPORTUNITE_PATCHED,PATCH_OPPORTUNITE,GET_TODAY_OPPORTUNITES,TODAY_OPPORTUNITES_RECEIVED,GET_INCOMING_OPPORTUNITES,INCOMING_OPPORTUNITES_RECEIVED,GET_OTHERS_OPPORTUNITES,OTHERS_OPPORTUNITES_RECEIVED,
OPPORTUNITES_RECEIVED,GET_OPPORTUNITES,DELETE_OPPORTUNITE, OPPORTUNITE_DELETED,AFFECT_OPPORTUNITE,OPPORTUNITE_AFFECTED,
    OPPORTUNITE_RECEIVED,GET_OPPORTUNITE,INSTALLATION_CREATED,SET_INSTALLATION,GET_INSTALLATIONS,
    INSTALLATIONS_RECEIVED,
    GET_INSTALLATIONS_OPPORTUNITE,
    INSTALLATIONS_OPPORTUNITE_RECEIVED,
    DELETE_INSTALLATION,
    INSTALLATION_DELETED,
    UPDATE_INSTALLATION,
    INSTALLATION_UPDATED

} from './constants';
import moment from 'moment';
import 'moment-duration-format';
import 'moment/min/moment-with-locales'
import 'moment/locale/fr'
import {getLoggedInUser} from "../../../helpers/authUtils";

const today=moment()
const incoming=moment()
const others=moment()



function* getOpportunites(action)
{
    let { filter} = action.payload;
    //?taches.deadline[before]=2020-11-26
    if(filter===undefined){filter=""}
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/opportunites'+filter, options);
        yield put({ type: OPPORTUNITES_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* getOpportunite(action)
{
    let { oppId} = action.payload;

    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/opportunites/'+oppId, options);
        yield put({ type: OPPORTUNITE_RECEIVED, json: response});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* getTodayOpportunites()
{
    const filter = "?taches.deadline[before]=2020-11-27";
    //

   //if(filter===undefined){filter="?taches.deadline[before]=2020-11-29&&taches.deadline[after]=2020-11-26"}
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/opportunites?taches.deadline[before]='+today.add(1,"days").format("YYYY/MM/DD"), options);
        yield put({ type: TODAY_OPPORTUNITES_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* getIncomingOpportunites()
{
    let { filter} = "?taches.deadline[before]=2020-11-29&&taches.deadline[after]=2020-11-26";
    //moment(today.add(1,"days")).format("YYYY/MM/DD")

    if(filter===undefined){filter="?taches.deadline[before]=2020-11-29&&taches.deadline[after]=2020-11-26"}
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/opportunites?taches.deadline[before]='+incoming.add(3,"days").format("YYYY/MM/DD")+'&&taches.deadline[after]='+today.format("YYYY/MM/DD"), options);


        yield put({ type: INCOMING_OPPORTUNITES_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* getOthersOpportunites()
{
    let { filter} = "?taches.deadline[after]=2020-11-29";
    //

    if(filter===undefined){filter=""}
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/opportunites?taches.deadline[after]='+incoming.format("YYYY/MM/DD"), options);
        yield put({ type: OTHERS_OPPORTUNITES_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* setOpportunite(action)
{
    const { data,history} = action.payload;
    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },

        body:JSON.stringify({
          //  Nom:data.Nom,
            prenom: data.prenom,
            nom: data.nom,
            ste: data.ste,
            sm: data.sm,
            adresse:data.adresse,
            email: data.email,
            tel1: data.tel1,
            tel2: data.tel2,
            refSteg: data.refSteg,
            remarque: data.remarque,
            villeOpportunite:data.ville[0],
            canalOpportunite:data.canaux[0],
            segments:data.segments,
            createdBy:data.owner,
            chargeCommercial:null,


        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/opportunites', options);

         yield put({
                type: OPPORTUNITE_ADDED,
                data
            });
        yield* getOpportunites()
       /* yield call(() => {
            history.push('/configuration/Opportunites');
        });*/

   }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: OPPORTUNITE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
            message="le Opportunite existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* updateOpportunite(action)
{


    const {data} = action.payload;

    let arrFichier=[]
    data.fichier.map((fich,index)=>{
        arrFichier.push(fich["@id"])
    })


    const uniquefiles = arrFichier.filter((val, id, array) => {
        return array.indexOf(val) == id;
    });







    let perdu=[]
    data.perdu.map((per,index)=>{
        perdu.push(per['@id'])
    })


    const options = {
        method: 'PUT',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}`
        },

        body:JSON.stringify({
            id:data.id,
            nom:data.nom,
            adresse:data.adresse,
            segment:data.segment['@id'],
            villesOpportunites:data.villesOpportunites,
            agence:data.agence['@id'],
            fichier:uniquefiles,
            etat:data.etat['@id'],
            perdu:perdu

        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/opportunites/'+data.id, options);
        yield put({
            type: OPPORTUNITE_PATCHED,
            data,
        });
   }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: OPPORTUNITE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Opportunite existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* affectOpportuniteToCommercial(action)
{
    const { opportunites,idCommercial} = action.payload;
    const commercial= yield call(fetchJSON,SERVERURI+'/commercials/'+idCommercial);



    for (var opportunite in opportunites) {

        const options = {
            method: 'PUT',
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
            },
            body:JSON.stringify({
               // opportunite:opportunites[0],
                chargeCommercial:commercial
            })
        }
        try {




            const response = yield call(fetchJSON,SERVERURI+'/opportunites/'+opportunites[opportunite], options);
            yield put({
                type: OPPORTUNITE_AFFECTED,
                // propsects,
            });



        }
        catch (error) {
            let message;
            switch (error.status) {
                case 500:

                    yield put({
                        type: OPPORTUNITE_REQUEST_FAILED,
                        error:error.status,
                        response:"Le Champ doit etre unqiue",
                        //json: response["hydra:member"],
                    });
                    message="le Opportunite existe veuillez choisir un autre nom"
                case 400:
                    message = 'Echec connexion';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = error;
            }
        }
    }
    yield* getOpportunites()



}
function* deleteOpportunite(action)
{
    const { history,id} = action.payload;
    const options = {
        method: 'DELETE',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },

        body:JSON.stringify({
            id
        })
    }
    try {

        const response = yield call(fetchJSON,SERVERURI+'/opportunites/'+id, options);
        yield put({
            type: OPPORTUNITE_DELETED,
            //data,

        });


    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: OPPORTUNITE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Opportunite existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}




function* opportuniteWatcher() {
   yield takeEvery(SET_OPPORTUNITE, setOpportunite)
   yield takeEvery(GET_OPPORTUNITES, getOpportunites)
    yield takeEvery(GET_TODAY_OPPORTUNITES, getTodayOpportunites)
    yield takeEvery(GET_INCOMING_OPPORTUNITES, getIncomingOpportunites)
    yield takeEvery(GET_OTHERS_OPPORTUNITES, getOthersOpportunites)
   yield takeEvery(PATCH_OPPORTUNITE, updateOpportunite)
    yield takeEvery(AFFECT_OPPORTUNITE, affectOpportuniteToCommercial)
   yield takeEvery(DELETE_OPPORTUNITE, deleteOpportunite)
    yield takeEvery(GET_OPPORTUNITE, getOpportunite)

}
function* setInstallation(action)
{
    const { data,history} = action.payload;
    const segData=data.segment["hydra:member"]

    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({


            segmentInstallation:data.segment['@id'],
            installationOpportunite:data.opportunite['@id'],
            puissanceInstallee:data.puissanceInstallee,
            puissancePompe:data.puissancePompe,
            connecteReseau:data.connecteReseau,
            siteIsole:data.siteIsole,
            reference:data.reference,
            backup:data.backup,
            autonomie:data.autonomie,
            capacite:data.capacite,
            historiqueConsommation:data.historiqueConsommation,

            dernierIndex:formatString(data.dernierIndex),
            puitAutorise:data.puitAutorise,
            comptant:data.comptant,
            subvention:data.subvention,
            prixPropose:formatString(data.prixPropose),
            facilite:data.facilite,
            avance:formatString(data.avance),
            mensualite:formatString(data.mensualite),
            credit:data.credit,
            organismeCredit:data.organismeCredit,
            autrePayement:data.autrePayement,
            remarquePayement:data.remarquePayement,
            compteur:data.compteur,
            extension:data.extension,
            nouvelleInstallation:data.nouvelleInstallation,
            anciennePuissanceInstallee:formatString(data.anciennePuissanceInstallee),
            dateMiseEnService:data.dateMiseEnService,
            indexImport:formatString(data.indexImport),
            indexExport:formatString(data.indexExport),
            ancienPayement:data.ancienPayement,
            historiqueOnduleur:formatString(data.historiqueOnduleur),
            nombreAnneeSteg:formatString(data.nombreAnneeSteg),
            creditSteg:data.creditSteg,
            dernierIndexGaz:formatString(data.dernierIndexGaz),

            typeMoteur:data.typeMoteur,
            dafef:data.dafef,
            puissanceDaffef:formatString(data.puissanceDaffef),
            typeDaffef:data.typeDaffef,





        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/installations', options);

        yield put({
            type: INSTALLATION_CREATED,
            data
        });


       yield* getInstallationsOpportunite(data.opportunite.id)
      //   yield call(getInstallationsOpportunite,);

    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: OPPORTUNITE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Opportunite existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}

function* getInstallationsOpportunite(action)
{




    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
       /* body:JSON.stringify({
            oppId
        })*/
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/installations?installationOpportunite='+action, options);
        yield put({ type: INSTALLATIONS_OPPORTUNITE_RECEIVED, json: response["hydra:member"]});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}


function* deleteInstallation(action)
{
    const {id,oppId} = action.payload;
    const options = {
        method: 'DELETE',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },


    }
    try {

        const response = yield call(deleteJSON,SERVERURI+'/installations/'+id, options);
        yield put({
            type: INSTALLATION_DELETED,
            //data,

        })

        yield* getInstallationsOpportunite(oppId)
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: OPPORTUNITE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Opportunite existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}

function* updateInstallation(action)
{


    const { data} = action.payload;

    const options = {
        method: 'PUT',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            //id:data.id,

            segmentInstallation:data.segmentInstallation['@id'],
          // installationOpportunite:data.opportunite['@id'],
            puissanceInstallee:formatString(data.puissanceInstallee),
            puissancePompe:formatString(data.puissancePompe),
            connecteReseau:data.connecteReseau,
            siteIsole:data.siteIsole,
            reference:data.reference.trim(),
            backup:data.backup,
            autonomie:formatString(data.autonomie),
            capacite:formatString(data.capacite),
            historiqueConsommation:formatString(data.historiqueConsommation),
            dernierIndex:formatString(data.dernierIndex),
            puitAutorise:data.puitAutorise,
            comptant:data.comptant,
            subvention:data.subvention,
            prixPropose:formatString(data.prixPropose),
            facilite:data.facilite,
            avance:formatString(data.avance),
            mensualite:formatString(data.mensualite),
            organismeCredit:data.organismeCredit,
            credit:data.credit,
            autrePayement:data.autrePayement,
            remarquePayement:data.remarquePayement,
            compteur:data.compteur,
            extension:data.extension,
            nouvelleInstallation:data.nouvelleInstallation,
            anciennePuissanceInstallee:formatString(data.anciennePuissanceInstallee),
            dateMiseEnService:data.dateMiseEnService,
            indexImport:formatString(data.indexImport),
            indexExport:formatString(data.indexExport),
            ancienPayement:data.ancienPayement,
            historiqueOnduleur:formatString(data.historiqueOnduleur),
            nombreAnneeSteg:formatString(data.nombreAnneeSteg),
            creditSteg:data.creditSteg,
            dernierIndexGaz:formatString(data.dernierIndexGaz),
            typeMoteur:data.typeMoteur,
            dafef:data.dafef,
            puissanceDaffef:formatString(data.puissanceDaffef),
            typeDaffef:data.typeDaffef,



        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/installations/'+data.id, options);
        yield put({
            type: INSTALLATION_UPDATED,
            data,
        });
      //  yield* getInstallationsOpportunite(data.opportunite.id)
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: OPPORTUNITE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Opportunite existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}



function* installationWatcher() {

    yield takeEvery(SET_INSTALLATION, setInstallation)
    yield takeEvery(GET_INSTALLATIONS_OPPORTUNITE, getInstallationsOpportunite)
    yield takeEvery(DELETE_INSTALLATION, deleteInstallation)
    yield takeEvery(UPDATE_INSTALLATION, updateInstallation)
}

function formatString(valeur){
    if(typeof valeur==="string")
    return parseFloat(valeur.replace(/\s/g, ''))
    else return valeur
}
////////////////////////////////////////
//////USERS SAGA////////////////////////
////////////////////////////////////////
export default function* opportuniteSaga() {
    yield all([
        opportuniteWatcher(),installationWatcher()
    ]);
}
