import React,{Suspense} from 'react';
import { connect } from 'react-redux';
import { getActions, usersReceived, getSelectedAction,getSelectedPriorite } from '../redux/crm/actions';
import { AvField, AvGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { CustomInput, FormGroup, Label } from 'reactstrap';

class ActionSelectSingle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            priorities: [{}],
            selectedPriorite: 0,
            // selectedAction: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.prioriteChange = this.prioriteChange.bind(this);
        //   this.expose_actions = this.expose_actions.bind(this);
    }
    getPriorites = async (priorite) => {
        return new Promise((resolve) => {
            resolve(priorite);
        });
    };
    prioriteChange =(e,priorite)=>{
        this.props.getSelectedPriorite(priorite)
        this.setState({
            selectedPriorite:priorite
        },function () {

        })

    }
    handleChange = (e) => {
        let id = e.target.value;
        let cible = this.props.actions.filter((act) => act.id.toString() === id.toString());

        //  alert(JSON.stringify(cible[0]));
        // this.props.setVillePosition(lat, long, population)
        this.props.getSelectedAction(cible[0]);
        this.setState({
            selectedAction: cible[0],
            selectedPriorite:0,
        });
    };

    componentDidMount() {

        this.props.getActions();

        this.setState({ actions: this.props.actions,
            selectedAction:this.props.selectedAction,

        })



      /*  setTimeout( this.setState({ actions: this.props.actions,
            selectedAction:this.props.selectedAction
        }), 3000);


       ;*/
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.state.selectedPriorite===0&&this.props.selectedAction!==undefined){
           // alert("yes")
            this.props.getSelectedPriorite(this.props.selectedAction.data.Priorites[0])
            this.setState({
                selectedPriorite:this.props.selectedAction.data.Priorites[0]
            },function () {

            })


        }


        if (this.props.selectedAction===undefined){
            this.props.getSelectedAction(this.props.actions[0]);
            this.setState({
                selectedAction: this.props.actions[0],
            });

        }
        if (prevProps.selectedAction !== this.props.selectedAction) {

            //alert("change")

            this.setState({ selectedAction: this.props.selectedAction})

           // alert(JSON.stringify(this.state.selectedAction.Priorites))




        }
        /*this.props.getSelectedAction(action);
        this.setState({ selectedAction: action });*/


      /*  if(!Array.isArray( this.props.selectedAction)){
            alert(JSON.stringify(this.props.selectedAction.id))

        }

        if (prevProps.selectedAction !== this.props.selectedAction) {


            let action = this.props.selectedAction;



            if (action['Priorites'].length === 1) {
                this.setState({ selectedAction: action });
            } /!*else {
                action['Priorites'] = action['Priorites'][0];
            }*!/
            this.props.getSelectedAction(action);
            this.setState({ selectedAction: action });
        }*/


    }

    expose_actions = ({ actions }) =>
        actions ? (
            <AvGroup>

                <AvField
                    type="select"
                    name={this.props.name}
                    label={this.props.label}
                    helpMessage={this.props.helpMessage}
                    onChange={this.handleChange}
                    /*value={this.state.selectedAction === null ? this.props.actions[0].id : this.state.selectedAction}*/

                    value={
                        this.state.selectedAction === null || this.state.selectedAction === undefined
                            ? this.props.default.id
                            : this.state.selectedAction.id
                    }>
                    {actions.map((action, index) => (
                        <option key={index} value={action.id}>
                            {action.nom}{' '}
                        </option>
                    ))}
                </AvField>

                <React.Fragment>
                    {

                        this.state.selectedAction.data===undefined?"":
                        <Suspense fallback={"<Loader></Loader>"}>
                            {
                                this.state.selectedAction.data.Priorites !== undefined &&this.state.selectedPriorite!==0?
                                    <AvRadioGroup inline name="urgence" label="Priorités" required value={

                                        this.state.selectedPriorite.id
                                }


                                    >
                                    {

                                        this.state.selectedAction.data.Priorites !== undefined?


                                            this.state.selectedAction.data.Priorites.map((priorite, index) => (
                                                <AvRadio
                                                    key={index}
                                                    type="radio"
                                                    id={index}
                                                    label={priorite.nom + ' (' + priorite.delais + 'h)'}
                                                    value={priorite.id}

                                                    onChange={(e)=>this.prioriteChange(e,priorite)}
                                                />
                                            ))

                                            :
                                            <div>{JSON.stringify(this.state.selectedAction.data.Priorites)}</div>


                                    }
                                </AvRadioGroup>
                                    :""
                            }


                    </Suspense>
                  }
                </React.Fragment>

                {/*     {this.props.actions[this.state.selectedAction]['Priorites'].length > 0 ? (
                    <AvRadioGroup inline name="urgence" label="Priorités" required value={this.props.actions[this.state.selectPriorite]['Priorites'][0].id}>
                        {this.props.actions[this.state.selectPriorite]['Priorites'].map((priorite, index) => (
                            <AvRadio
                                key={index}
                                type="radio"
                                id={index}
                                label={priorite.nom + ' (' + priorite.delais + 'h)'}
                                value={priorite.id}
                            />
                        ))}
                    </AvRadioGroup>
                ) : null}*/}
            </AvGroup>
        ) : null;

    render() {
        return (
            <div>

                {
                    this.state.selectedAction!==undefined&&this.state.actions!==undefined?
                    this.expose_actions(this.props):
                    "<Loader></Loader>"

                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    getActions,
    getSelectedAction,
    getSelectedPriorite,
};
const mapStateToProps = (state, action) => ({
    actions: state.Crm.actions,
    selectedAction: state.Crm.selectedAction,
    selectedPriorite: state.Crm.selectedPriorite,
});
ActionSelectSingle = connect(mapStateToProps, mapDispatchToProps, null)(ActionSelectSingle);

export default ActionSelectSingle;
