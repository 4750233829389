// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { fetchJSON, insertJSON,SERVERURI } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD} from './constants';


import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
} from './actions';

//let cookies1 = new Cookies();

/**
 * Sets the session
 * @param {*} user
 */
const setSession = user => {
    let cookies = new Cookies();

    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};



/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password,fcm } }) {
    const options = {
        body: JSON.stringify({ username, password}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
        'User-Agent':'multipart/form-data'},
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/login_check', options);

        const userData = jwtDecode(JSON.stringify(response.token));
        const commercial= yield call(fetchJSON,SERVERURI+'/commercials/'+userData.Id,{
            method: 'GET',
            headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${response.token}` },

        });


        let responseJson = {
            id: userData.Id,

            firstName: userData.Prenom,
            lastName: userData.Nom,
            role: userData.roles[0],
            commercial:userData.commercial,
            isActive:userData.isActive,
            isBlocked:userData.isBlocked,
            token:response.token,
            photo: commercial.photo,
            chef:userData.chef,
            coordinateur:userData.coordinateur,
            agent:userData.agent,
            agenceId:userData.agenceId,
            agenceNom:userData.agenceNom,
            dv:userData.dv,
            dc:userData.dc,
            messanger:userData.messanger,

        };
        const fcmToken= yield call(insertJSON,SERVERURI+'/f_c_m_tokens',{
            method: 'POST',
            headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${response.token}` },
            body:JSON.stringify({
                "token": fcm,
                "user": userData.Id
            })
        });

       // console.log("session data==>", responseJson)
        setSession(responseJson);
        yield put(loginUserSuccess(responseJson));
    } catch (error) {
        let message;

        switch (error.message) {
            case "Invalid token specified":
                message = 'Echec connexion. Vérifiez vos paramètres d\'authentification';
                break;
           /* case 401:
                message = 'Invalid credentials';
                break;*/
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}
/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/ld+json' },
        //axios.defaults.headers.common = {'Authorization': `Bearer ${token}`,'Content-Type':"application/ld+json"};
    };
    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
