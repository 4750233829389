// @flow

import { put,take, takeLatest,all,call, takeEvery,takeLeading } from 'redux-saga/effects';
import { fetchJSON ,insertJSON,SERVERURI,TOKEN} from '../../../helpers/api';
import {SET_TACHE,TACHE_ADDED,TACHE_REQUEST_FAILED,TACHE_PATCHED,PATCH_TACHE,


    TACHES_RECEIVED,GET_TACHES,DELETE_TACHE, TACHE_DELETED,AFFECT_TACHE,TACHE_AFFECTED,GET_TACHE,
    TACHE_RECEIVED, OPPORTUNITE_RECEIVED,GET_OPPORTUNITE,SET_REMARQUE_TACHE,REMARQUE_TACHE_ADDED,
    AFFECT_TACHE_FROM_OPPORTUNITE,AFFECTED_TACHE_FROM_OPPORTUNITE

} from './constants';
//import {AGENCE_REQUEST_FAILED} from "./agence/constants";
import moment from 'moment';
import 'moment-duration-format';
import {getLoggedInUser} from "../../../helpers/authUtils";
import 'moment/min/moment-with-locales'
import {PROSPECT_AFFECTED, PROSPECT_REQUEST_FAILED} from "../prospect/constants";
function renderRole(role,idCommercial,idAgence,dateDebut,DateFin){



    let requete
    switch (role) {
        case 'byExecuteur':
            requete="?executeur.id[]="+idCommercial;

            return  requete
        case 'byDemandeur':
            requete="?demandeur.id[]="+idCommercial;
            return  requete
        case 'mesTaches':
            requete="?executeur.id="+idCommercial;
            if(dateDebut!=="")
            {
                //requete=requete+"&startDate[after]="+dateDebut;
                requete=requete+"&deadline[after]="+dateDebut
            }
            if(DateFin!=="")
            {
                requete=requete+"&deadline[strictly_before]="+dateDebut
            }
            return  requete
        case 'tachesAgence':
            requete="?agenceTache.id="+idAgence
            if(dateDebut!=="")
            {
                requete=requete+"&deadline[after]="+dateDebut
              //  requete=requete+"&deadline[strictly_before]="+dateDebut+"&&startDate[after]="+dateDebut;
               //requete=requete+"&startDate[after]="+dateDebut+"&startDate[strictly_before]="+moment(dateDebut, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
            }
            if(DateFin!=="")
            {
                requete=requete+"&deadline[strictly_before]="+dateDebut
            }
            return  requete;
            case 'expiredTaches':
                requete="?executeur.id="+idCommercial;
            if(dateDebut!=="")
            {
                requete=requete+"&deadline[strictly_before]"+dateDebut;
            }
            if(DateFin!=="")
            {
                requete=requete+"&deadline[strictly_before]="+dateDebut
            }
            return  requete;
            case 'expiredTachesAgence':
                requete="?agenceTache.id="+idAgence
            if(dateDebut!=="")
            {
                requete=requete+"&deadline[strictly_before]"+dateDebut;
            }
            if(DateFin!=="")
            {
                requete=requete+"&deadline[strictly_before]="+dateDebut
            }
            return  requete;
        case 'expiredTachesTous':
            requete=""
            if(dateDebut!=="")
            {
                requete=requete+"?deadline[strictly_before]="+dateDebut;
            }
            if(DateFin!=="")
            {
                requete=requete+"&deadline[strictly_before]="+dateDebut
            }
            return  requete;
        case 'references':
            requete="?action.interface=reference&agenceTache.id="+idAgence

            if(dateDebut!=="")
            {
                requete=requete+"?deadline[strictly_before]="+dateDebut;
            }
            if(DateFin!=="")
            {
                requete=requete+"&deadline[strictly_before]="+dateDebut
            }
            return  requete;
        default:

            requete=""
            if(dateDebut!=="")
            {
                requete=requete+"?deadline[after]="+dateDebut
            }
            if(DateFin!=="")
            {
                requete=requete+"&deadline[strictly_before]="+dateDebut
            }
            return  requete;
    }
}
function* getTaches(action)
{
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const {role,idCommercial,idAgence,dateDebut,DateFin} = action.payload;
       // SERVERURI+'/prospects'+renderRole(role,idCommercial,idAgence
        const response = yield call(fetchJSON, SERVERURI+'/taches'+renderRole(role,idCommercial,idAgence,dateDebut,DateFin)
            , options);
        yield put({ type: TACHES_RECEIVED, json: response["hydra:member"],role});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* getOpportunite(action)
{
    let { oppId} = action.payload;
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/opportunites/'+oppId, options);
        yield put({ type: OPPORTUNITE_RECEIVED, json: response});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* getTache(action)
{
    const {tacheId} = action.payload;
    const options = {
        method: 'GET',headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${TOKEN()}` },
    };
    try {
        const response = yield call(fetchJSON, SERVERURI+'/taches/'+tacheId, options);
        yield put({ type: TACHE_RECEIVED, json: response});
    } catch (error) {
        let message;
        switch (error.status) {
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* setTache(action)
{
    const { data,history} = action.payload;
    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },

        body:JSON.stringify({
          //  Nom:data.Nom,
            prenom: data.prenom,
            nom: data.nom,
            ste: data.ste,
            sm: data.sm,
            email: data.email,
            tel1: data.tel1,
            tel2: data.tel2,
            refSteg: data.refSteg,
            remarque: data.remarque,
            villeTache:data.ville[0],
            canalTache:data.canaux[0],
            segments:data.segments,
            createdBy:data.owner,
            chargeCommercial:null,
        })
    }
    try {

        const response = yield call(insertJSON,SERVERURI+'/taches', options);

         yield put({
                type: TACHE_ADDED,
                data
            });
        yield* getTaches()


   }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: TACHE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
            message="le Tache existe veuillez choisir un autre nom"


            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}

function* setRemarqueTache(action)
{
    const { id,commercial,remarque} = action.payload;

    const options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            note: remarque,
            editeur: {
                id:commercial
            },
            tachesRemarques:[{id:id}]
        })
    }
    try {
        const response = yield call(insertJSON,SERVERURI+'/remarques', options);
        yield put({
            type: REMARQUE_TACHE_ADDED,
            remarque
        });
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:
                yield put({
                    type: TACHE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Tache existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* updateTache(action)
{
    const { data,history} = action.payload;
    const options = {
        method: 'PUT',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            demandeur: data.demandeur,
            executeur: data.executeur,
            agence: data.agence,
            segment: data.segment,
            action: data.action,
            priorite: data.priorite,
            startDate: data.startDate,
            opportuniteParent:data.opportuniteParent,
            deadline: data.deadline,
            prospect: data.prospect,
            etat: data.etat,
            retard:data.retard,
            refCompteur:data.refCompteur,
            agenceTache: data.agenceTache,
            accompli:data.accompli,
            annulee:data.annulee,
            dateCloture:data.dateCloture,
            report:data.report,
        })
    }
    try {

        const response = yield call(fetchJSON,SERVERURI+'/taches/'+data.id, options);
        yield put({
            type: TACHE_PATCHED,
            data,
        });
   }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:
                yield put({
                    type: TACHE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Tache existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}
function* affectTacheToCommercial(action)
{
    const { taches,idCommercial} = action.payload;
    const commercial= yield call(fetchJSON,SERVERURI+'/commercials/'+idCommercial);



    for (var tache in taches) {

        const options = {
            method: 'PUT',
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
            },
            body:JSON.stringify({
               // tache:taches[0],
                chargeCommercial:commercial
            })
        }
        try {

            const response = yield call(fetchJSON,SERVERURI+'/taches/'+taches[tache], options);
            yield put({
                type: TACHE_AFFECTED,
                // propsects,
            });
        }
        catch (error) {
            let message;
            switch (error.status) {
                case 500:
                    yield put({
                        type: TACHE_REQUEST_FAILED,
                        error:error.status,
                        response:"Le Champ doit etre unqiue",
                        //json: response["hydra:member"],
                    });
                    message="le Tache existe veuillez choisir un autre nom"
                case 400:
                    message = 'Echec connexion';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = error;
            }
        }
    }
    yield* getTaches()



}
function* deleteTache(action)
{
    const { history,id} = action.payload;
    const options = {
        method: 'DELETE',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },
        body:JSON.stringify({
            id
        })
    }
    try {

        const response = yield call(fetchJSON,SERVERURI+'/taches/'+id, options);
        yield put({
            type: TACHE_DELETED,
            //data,

        });
    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:

                yield put({
                    type: TACHE_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Tache existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
}

function* affectTacheFromOpportunite(action)
{

    const{data,history}=action.payload

    let options = {
        method: 'POST',
        headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json','Authorization': `Bearer ${TOKEN()}`
        },



        body:JSON.stringify({
            demandeur: data.demandeur,
            executeur: data.executeur,
            agence: data.agence,
            segment: data.segment,
            action: data.action,
            priorite: data.priorite,
            startDate: data.startDate,
            opportuniteParent:data.opportuniteParent,
            deadline: data.deadline,
            prospect: data.prospect,
            etat: data.etat,
            agenceTache: data.agenceTache,
            refCompteur:data.refCompteur
        })
    }

    try {
        const response = yield call(insertJSON,SERVERURI+'/taches', options);


        yield put({
            type: AFFECTED_TACHE_FROM_OPPORTUNITE,status:"info", notifyMessage:"Demande effectuée"     // propsects,
        });

    }
    catch (error) {
        let message;
        switch (error.status) {
            case 500:
                yield put({
                    type: PROSPECT_REQUEST_FAILED,
                    error:error.status,
                    response:"Le Champ doit etre unqiue",
                    //json: response["hydra:member"],
                });
                message="le Prospect existe veuillez choisir un autre nom"
            case 400:
                message = 'Echec connexion';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
    }
    //  yield* getProspects(action)
}
function* tacheWatcher() {
   yield takeEvery(SET_TACHE, setTache)
   yield takeEvery(GET_TACHES, getTaches)
    yield takeEvery(GET_TACHE, getTache)
    yield takeEvery(GET_OPPORTUNITE, getOpportunite)
    yield takeEvery(SET_REMARQUE_TACHE, setRemarqueTache)
    yield takeEvery(PATCH_TACHE, updateTache)
    yield takeEvery(AFFECT_TACHE, affectTacheToCommercial)
   yield takeEvery(DELETE_TACHE, deleteTache)
    yield takeEvery(AFFECT_TACHE_FROM_OPPORTUNITE, affectTacheFromOpportunite)


}
////////////////////////////////////////
//////USERS SAGA////////////////////////
////////////////////////////////////////
export default function* tacheSaga() {
    yield all([
        tacheWatcher()
    ]);
}
