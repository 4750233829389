// @flow
import {
   
    SET_PROSPECT,
    PROSPECT_ADDED,
    PROSPECT_REQUEST_FAILED,
    GET_PROSPECTS,
    PROSPECTS_RECEIVED, PROSPECT_PATCHED,PATCH_PROSPECT,DELETE_PROSPECT, PROSPECT_DELETED,AFFECT_PROSPECT,PROSPECT_AFFECTED,
    GET_PROSPECT,
    PROSPECT_RECEIVED,VERIFY_PROSPECT,VALID_PROSPECT_RECEIVED,NOTIFY_PROSPECT_USER, SET_OPPORTUNITE_FROM_PROSPECT,
    OPPORTUNITE_ADDED_FROM_PROSPECT
} from './constants';


const Prospect = (state ={}, action) => {
    switch (action.type) {
      
        case SET_PROSPECT:
            return { ...state, prospectAdded:true,loading: true };
        case PROSPECT_ADDED:
            return { ...state,dateAdd:new Date(),notifyMessageAdd:action.notifyMessage ,statusAdd:action.status, loading:false,  addedProspect:action.addedProspect };

        case PATCH_PROSPECT:
            return { ...state, prospectPatched:true,loading: true };
        case PROSPECT_PATCHED:
            return { ...state, updatedProspect:action.updatedProspect, loading:false };


        case AFFECT_PROSPECT:
            return { ...state, prospectAffected:true,loading: true, };
        case PROSPECT_AFFECTED:
            return { ...state, loading:false,notifyMessage:action.notifyMessage ,status:action.status};

        case DELETE_PROSPECT:
            return { ...state, prospectDeleted:true,loading: true };
        case PROSPECT_DELETED:
            return { ...state, loading:false };
        case PROSPECT_REQUEST_FAILED:
            return { ...state,  error:action.error, loading:false };

        case GET_PROSPECTS:
            return { ...state, loading: true, notifyMessage:action.notifyMessage ,status:action.status };
        case PROSPECTS_RECEIVED:
            switch (action.role) {

                case 'mesProspects':
                    return { ...state, prospects:action.json,role: action.role ,loading: false, notifyMessage:action.notifyMessage ,status:action.status }
                case 'prospectsAgence':
                    return { ...state, prospects:action.json,role: action.role ,loading: false, notifyMessage:action.notifyMessage ,status:action.status }
                case 'allProspects':
                    return { ...state, prospects:action.json,role: action.role ,loading: false, notifyMessage:action.notifyMessage ,status:action.status }


                default:
                    return { ...state, prospects:action.json,role: action.role ,loading: false, notifyMessage:action.notifyMessage ,status:action.status }
            }


        case GET_PROSPECT:
            return { ...state, loading: true };
        case PROSPECT_RECEIVED:
            return { ...state, prospect:action.json, loading: false}

        case VERIFY_PROSPECT:
            return { ...state, loading: true };
        case  VALID_PROSPECT_RECEIVED:
            return { ...state,isValid:action.isValid,foundedProspect:action.response , loading: false}


        case SET_OPPORTUNITE_FROM_PROSPECT:
            return { ...state, opportuniteAdded:true,loading: true };
        case OPPORTUNITE_ADDED_FROM_PROSPECT:
            return { ...state,dateAdd:new Date(),notifyOppProspectMessage:action.notifyOppProspectMessage ,statusOppProspectMessage:action.statusOppProspectMessage, loading:false };
        default:
            return state;

    }
};
export default Prospect