import React from 'react';
import {connect} from 'react-redux';
import {getVilles, setVillePosition, getSelectedVille} from '../redux/crm/actions';
import {AvField} from "availity-reactstrap-validation";
import Loader from "./Loader";

class VillesSelectSingle extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            villes: [],
            selectedVille:null,
            latitude: 0,
            longitude: 0,
            population: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        if (e.target.options[e.nativeEvent.target.selectedIndex].attributes.lat !== undefined) {
            let lat = e.target.options[e.nativeEvent.target.selectedIndex].attributes.lat.value
            let long = e.target.options[e.nativeEvent.target.selectedIndex].attributes.long.value
            let population = e.target.options[e.nativeEvent.target.selectedIndex].attributes.population.value

            this.setState({
                selectValue: e.target.value,
                latitude: e.target.options[e.nativeEvent.target.selectedIndex].attributes.lat.value,
                longitude: e.target.options[e.nativeEvent.target.selectedIndex].attributes.long.value,
                population: e.target.options[e.nativeEvent.target.selectedIndex].attributes.population.value,

            })
            let ville = e.target.options[e.nativeEvent.target.selectedIndex].attributes.ville.value
            let id = e.target.value
            this.props.setVillePosition(lat, long, population)
            this.props.getSelectedVille(id, ville)

        }
        //  console.log("change", e.nativeEvent.target.selectedIndex)
        //  console.log("change", e.target.options[e.nativeEvent.target.selectedIndex].attributes.lat.value)
        // console.log("change", e.target.options[e.nativeEvent.target.selectedIndex].attributes.long.value)

    }


    componentDidMount() {
       // this.props.getVilles()
        /*if(this._isMounted ===true)
        {*/
            this.setState({selectedVille: this.props.default})
            console.log("viiiiiiiiiiiiillllllllllllllllllle", this.props.default)
   /*     }*/



    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedVille !== this.props.selectedVille) {
            this.setState({
                selectedVille: this.props.selectedVille[0].id
            })
          //  console.log("viiiiiiiiiiiiillllllllllllllllllle", this.props.selectedVille)
        }
    }

    componentWillUnmount() {
        this._isMounted=false
    }


    expose_villes = ({villes}) => (
        villes ?
            <AvField type="select" name="villeAgence" label="Ville" helpMessage="Veuillez choisir une villle"
                     value={ this.state.selectedVille===null?this.props.default:this.state.selectedVille} onChange={this.handleChange}>

                {villes.map((ville, index) => <option key={index}  value={ville.id} ville={ville.ville} lat={ville.lat}
                                                      long={ville.lng}
                                                      population={ville.population}>{ville.ville}</option>)}

            </AvField>
            : null
    );


    render() {


        return (


            <div>
                {this.props.loading == true && this.props.getVilles() ? <Loader/> : this.expose_villes(this.props)}

            </div>


        )
    }


}

const mapDispatchToProps = {
    getVilles,
    setVillePosition,
    getSelectedVille
};
const mapStateToProps = (state) => ({
    villes: state.Crm.villes,
    loading: state.Crm.loading,
    selectedVille:state.Crm.selectedVille,
});
VillesSelectSingle = connect(mapStateToProps, mapDispatchToProps, null)(VillesSelectSingle)

export default VillesSelectSingle;


