/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
//export const SERVERUIMAGESUSERS='https://crm.greenpower.com.tn/apicrmv1/public/uploads/images/users/'
//export const SERVERURI='https://crm.greenpower.com.tn/apicrmv1/public/api'

import {getLoggedInUser,isUserAuthenticated} from "./authUtils";
import { InvenioSearchApi,SearchBar, ResultsList } from 'react-searchkit';


/*
export const CLIENTFILES='https://crm.greenpower.com.tn/apicrmv3/public/uploads/clients'
export const FILES='https://crm.greenpower.com.tn/apicrmv3/public/uploads/users'

export const APISERVERUIMAGESUSERS='https://crm.greenpower.com.tn/apicrmv3/public/api/media_objects'
export const SERVERUIMAGESUSERS='https://crm.greenpower.com.tn/apicrmv3/public/uploads/users/'

export const APISERVERUIMAGESCLIENTS='https://crm.greenpower.com.tn/apicrmv3/public/api/client_files'
export const SERVERUIMAGESCLIENTS='https://crm.greenpower.com.tn/apicrmv3/public/uploads/clients/'

export const APISERVERURFILESOPPORTUNITES='https://crm.greenpower.com.tn/apicrmv3/public/api/opportunite_files'
export const SERVERURFILESOPPORTUNITES='https://crm.greenpower.com.tn/apicrmv3/public/uploads/opportunites/'

export const SERVERURI='https://crm.greenpower.com.tn/apicrmv3/public/api'
*/



/*export const CLIENTFILES='https://192.168.1.214/gpcCRMv0/public/uploads/clients'
export const FILES='https://192.168.1.214/gpcCRMv0/public/uploads/users'

export const APISERVERUIMAGESUSERS='https://192.168.1.214/gpcCRMv0/public/api/media_objects'
export const SERVERUIMAGESUSERS='https://192.168.1.214/gpcCRMv0/public/uploads/users/'

export const APISERVERUIMAGESCLIENTS='https://192.168.1.214/gpcCRMv0/public/api/client_files'
export const SERVERUIMAGESCLIENTS='https://192.168.1.214/gpcCRMv0/public/uploads/clients/'

export const APISERVERURFILESOPPORTUNITES='https://192.168.1.214/gpcCRMv0/public/api/opportunite_files'
export const SERVERURFILESOPPORTUNITES='https://192.168.1.214/gpcCRMv0/public/uploads/opportunites/'

export const SERVERURI='https://192.168.1.214/gpcCRMv0/public/api'*/

/*export const CLIENTFILES='https://localhost/gpcCRMv0/public/uploads/clients'
export const FILES='https://localhost/gpcCRMv0/public/uploads/users'

export const APISERVERUIMAGESUSERS='https://localhost/gpcCRMv0/public/api/media_objects'
export const SERVERUIMAGESUSERS='https://localhost/gpcCRMv0/public/uploads/users/'

export const APISERVERUIMAGESCLIENTS='https://localhost/gpcCRMv0/public/api/client_files'
export const SERVERUIMAGESCLIENTS='https://localhost/gpcCRMv0/public/uploads/clients/'

export const APISERVERURFILESOPPORTUNITES='https://localhost/gpcCRMv0/public/api/opportunite_files'
export const SERVERURFILESOPPORTUNITES='https://localhost/gpcCRMv0/public/uploads/opportunites/'

export const SERVERURI='https://localhost/gpcCRMv0/public/api'*/


/*
export const CLIENTFILES='https://localhost/GPCCRMV1/public/uploads/clients'
export const FILES='https://localhost/GPCCRMV1/public/uploads/users'

export const APISERVERUIMAGESUSERS='https://127.0.0.1:8000/api/media_objects'
export const SERVERUIMAGESUSERS='https://localhost/GPCCRMV1/public/uploads/users/'

export const APISERVERUIMAGESCLIENTS='https://localhost/GPCCRMV1/public/api/client_files'
export const SERVERUIMAGESCLIENTS='https://localhost/GPCCRMV1/public/uploads/clients/'
export const APISERVERURFILESOPPORTUNITES='https://127.0.0.1:8000/api/opportunite_files'
export const SERVERURFILESOPPORTUNITES='https://localhost/GPCCRMV1/public/uploads/opportunites/'
export const SERVERURI='https://127.0.0.1:8000/api'

*/


export const CLIENTFILES='https://demo.greenpower.com.tn/apicrmv4/public/uploads/clients'
export const FILES='https://demo.greenpower.com.tn/apicrmv4/public/uploads/users'

export const APISERVERUIMAGESUSERS='https://demo.greenpower.com.tn/apicrmv4/public/api/media_objects'
export const SERVERUIMAGESUSERS='https://demo.greenpower.com.tn/apicrmv4/public/uploads/users/'

export const APISERVERUIMAGESCLIENTS='https://demo.greenpower.com.tn/apicrmv4/public/api/client_files'
export const SERVERUIMAGESCLIENTS='https://demo.greenpower.com.tn/apicrmv4/public/uploads/clients/'

export const APISERVERURFILESOPPORTUNITES='https://demo.greenpower.com.tn/apicrmv4/public/api/opportunite_files'
export const SERVERURFILESOPPORTUNITES='https://demo.greenpower.com.tn/apicrmv4/public/uploads/opportunites/'

export const SERVERURI='https://demo.greenpower.com.tn/apicrmv4/public/api'



/*export const SEARCHAPITACHE = new InvenioSearchApi({
    axios: {
        url: SERVERURI+'/taches',
        timeout: 5000,
        headers: { 'Content-Type': 'application/ld+json','Authorization': `Bearer ${getLoggedInUser().token}` }
    },
});*/

const TOKEN= () => {

    if(isUserAuthenticated())
    {
        return getLoggedInUser().token
    }
    else{

        return null
    }
};
export { TOKEN};






const fetchJSON = (url, options) => {
    return fetch(url, options)
        .then(async response => {
            const data = await response.json();
            if (!response.ok) {


                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

            return data;
        })
        .then(json => {
            return json;
        })
        .catch(error => {

            throw error;
        });
};

export { fetchJSON };

const insertJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(async response => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status

                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

           return data
        })
        .catch(error => {
            throw error
           // this.setState({ errorMessage: error.toString() });

        });
};

export {insertJSON};


const deleteJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {

            if (response.status !== 204) {

                throw response.json();
            }
            return response
        })
        .then(json => {

            return json;
        })
        .catch(error => {
            throw error;
        });
};

export { deleteJSON  };
