import React, { Component, useState  } from 'react';

import Routes from './routes/Routes';
import { onMessageListener, getToken } from "./firebaseInit";
import NotificationsWrapper from "./components/Notifications/Notifications";
import ReactNotificationComponent from "./components/Notifications/ReactNotification";
// setup fake backend
//import {frontend, configureFrontend} from './helpers';

// Themes

// default
import './assets/scss/theme.scss';

import {setRTLTextPlugin} from "react-map-gl";


setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
    null,
    true // Lazy load the plugin
);



/*
class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      show:false,
      setShow:false,
      notification:{ title: "", body: "" },
      setNotification:{ title: "", body: "" },
    }



  }



  componentDidMount() {
      onMessageListener()
          .then((payload) => {

              this.setState({
                  setShow:true,
                  setNotification:{
                      title: payload.notification.title,
                      body: payload.notification.body,
                  }
              })
              console.log("notification payload",payload);
          })
          .catch((err) => console.log("failed: ", err));

  }

  render() {

    return <Routes>

      </Routes>



  }
}

export default App;
*/
function App() {
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: "", body: "" });

    console.log(show, notification);

    onMessageListener()
        .then((payload) => {

            setShow(true);
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
            });
            console.log(payload);
        })
        .catch((err) => console.log("failed: ", err));

    return (
        <React.Fragment>
            <div className="App">
                {show ? (
                    <ReactNotificationComponent
                        title={notification.title}
                        body={notification.body}
                    />
                ) : (
                    <></>
                )}
                <NotificationsWrapper />

            </div>
            <Routes>

            </Routes>


        </React.Fragment>

    );
}

export default App;
